import React from 'react';
import { Card, Container, Modal } from 'react-bootstrap';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import ProfileImage from '../../images/profile.jpg';
import styles from '../feed/feed.module.css';
import createStyles from './create.module.css';
import Slider from '@material-ui/core/Slider';
import { useDispatch } from 'react-redux';
import { switchProfile } from '../../redux-store/action';
import EmailCopy from '../../images/copy.png';
import ViewIcon from '../../images/view.png';
import Menu from '../../components/menu';
import PrivateIcon from '../../images/restriction.png';
import CreatableSelect from 'react-select/creatable';
import SelectOptions from '../data';
import { useHistory } from 'react-router-dom';
import { CreateData } from '../../interface';

function valuetext(value) {
    return `${value}°C`;
}

const marks = [
    {
        value: 0,
        label: '$0',
    },
    {
        value: 100,
        label: '$100',
    },
];


const Create: React.FC<{ createCloseHandler: (e?: any) => void }> = (props) => {
    const dispatch = useDispatch();
    const [value, setValue] = React.useState([20, 37]);
    const [selectedValue, setSelectedValue] = React.useState('Yes');
    const [show, setShow] = React.useState(false);
    const [posted, SetPosted] = React.useState(true);
    const [createData, SetCreatorData] = React.useState({});
    const handleClose = () => setShow(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        SetCreatorData({ ...createData, priceRangeMin: newValue[0], priceRangeMax: newValue[1] });
    };
    const [viewable, SetViewable] = React.useState(false);

    const ToggleAccess = () => {
        SetViewable(!viewable);
    }

    const handleCheckChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const filterOperation = (el) => {
        const operation = [];
        if (el !== null) {
            el.map(function (data) {
                operation.push(data.value);
            });
        }
        return operation;
    }

    const FilterQueryHandler = (e, type) => {
        switch (type) {
            case "domain":
                SetCreatorData({ ...createData, domain: filterOperation(e) });
                break;
            case "target":
                SetCreatorData({ ...createData, target: filterOperation(e) });
                break;
            case "niche_target":
                SetCreatorData({ ...createData, niche_target: filterOperation(e) });
                break;
            default:
                break;
        }
    }

    const history = useHistory();

    const createPost = (e) => {
        const newObj = { ...createData, private: viewable, share_email: (selectedValue === "Yes") ? true : false, priceRangeMin: value[0], priceRangeMax: value[1] };
        SetPosted(false);
        // try{
        //     const config = {
        //       method:'POST',
        //       body:JSON.stringify(form),
        //       headers:{
        //         Accept:'application/json',
        //         'content-type':'application/json'
        //       }
        //     };
        //     const response = await fetch('http://192.168.1.7:5000/peers/login',config)
        //     const data = await response.json()
        //     tokenSetter(data.peer.name,data.token,data.peer._id)
        //   }catch(error){
        //     console.log(error)
        // }
    }

    const closeHandler = () => {
        props.createCloseHandler();
    }

    const postInputHandler = (e) => {
        SetCreatorData({ ...createData, [e.target.name]: e.target.value });
    }

    const profileSwitcher = () => {
        dispatch(switchProfile(true));
        history.push('/');
    }

    return (
        <div>
            <div className="mx-auto">
                {(posted) ? <Card className={"mx-auto " + createStyles.profileCard}>
                    <Card.Header onClick={profileSwitcher} style={{ cursor: 'pointer' }} className={"d-flex align-items-center " + styles.feedCard}><img src={ProfileImage} className={styles.feedProfile} /><span className="ml-2">DMatiz</span></Card.Header>
                    {/* <Card.Img variant="top" src={SampleImage} /> */}
                    <label htmlFor="file-input"><div className={"d-flex " + styles.feedcreate}><span className="align-self-center mx-auto">DROP MEDIA HERE</span></div></label>
                    <label htmlFor="file-input"><div className={"d-flex " + styles.feedcreate} style={{height:'100px'}}><span className="align-self-center mx-auto">Product Logo</span></div></label>
                    <input id="file-input" type="file" style={{ display: 'none' }} />
                    <Card.Body>
                        <div className="d-flex">
                            <input value={createData["product_title"] || ""} onChange={postInputHandler} className={"mr-2 form-control " + createStyles.createInput} name="product_title" placeholder="Product, Concept or Idea Name" />
                            {(viewable) ? <button className={styles.accessSpecifierPrivate} onClick={ToggleAccess}><img style={{ width: 18 }} src={PrivateIcon} /> <span>Private</span></button> : <button className={styles.accessSpecifier} onClick={ToggleAccess}><img style={{ width: 18 }} src={ViewIcon} /> <span>Public</span></button>}
                        </div>
                        <input value={createData["embed_link"] || ""} onChange={postInputHandler} className={"mr-2 form-control " + createStyles.createInput} name="embed_link" placeholder="Embeddable Link for Product Demo" />
                        <textarea className={"mt-3 form-control " + createStyles.createTextArea} onChange={postInputHandler} value={createData["product_desc"] || ""} name="product_desc" placeholder="Product Description"></textarea>
                        <label className={"mt-2 " + styles.feedLabel}>Target Audience</label>
                        <CreatableSelect isClearable isMulti className="mb-3" onChange={(e) => FilterQueryHandler(e, 'domain')} placeholder="Domain" options={SelectOptions.domain} />
                        <CreatableSelect isClearable isMulti className="mb-3" onChange={(e) => FilterQueryHandler(e, 'target')} placeholder="Target" options={SelectOptions.target} />
                        <CreatableSelect isClearable isMulti className="mb-3" onChange={(e) => FilterQueryHandler(e, 'niche_target')} placeholder="Niche Target" options={SelectOptions.niche_target} />
                        <div>
                            <Slider
                                value={value}
                                onChange={handleChange}
                                className={styles.sliderCustom}
                                aria-labelledby="range-slider"
                                getAriaValueText={valuetext}
                                valueLabelDisplay="on"
                                marks={marks}
                            />

                            <div className="d-flex"><p className={"mt-3 " + styles.creatorFont}>Share your email id with users?</p>
                                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                    <FormControlLabel
                                        value="Yes"
                                        control={<Radio color="primary" checked={selectedValue === 'Yes'} />}
                                        onChange={handleCheckChange}
                                        className={styles.creatorFont}
                                        label="Yes"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        value="No"
                                        control={<Radio color="primary" checked={selectedValue === 'No'} />}
                                        onChange={handleCheckChange}
                                        label="No"
                                        className={styles.creatorFont}
                                        labelPlacement="start"
                                    />
                                </RadioGroup></div>
                            <button className={styles.willpay + " mr-auto"} onClick={createPost}>CREATE</button>
                        </div>
                    </Card.Body>
                </Card> : <Card body className={styles.createdMessage}>
                    <div>Your Product has been successfully listed!</div>
                    <p style={{ fontSize: 18, marginTop: 14, color: '#999999' }}>Follow the feed to track your product.</p>
                    <img src="https://media.giphy.com/media/B150T0UlLdFMkTFv3H/giphy.gif" style={{ width: 180 }} />
                    <div className="mt-3">
                        <button className={styles.TabActive} onClick={closeHandler}>CLOSE</button>
                    </div>
                </Card>}
            </div>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body className="text-center p-4">
                    <p className={styles.feedModalHeader}>Thank you for providing your feedback!</p>
                    <p className={styles.feedModalDesc}>Copy the creators email below!</p>
                    <span className={styles.feedModalEmailCopy}><img src={EmailCopy} className={styles.feedEmailLogo} /> sriharsha19mel@gmail.com</span>
                    <div className="text-center mt-3">
                        <button className={styles.TabActive} onClick={handleClose}>FEED</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Create;
