import React from 'react';
import Menu from '../../components/menu';
import Profile from './feedlayout4';
// import FeedLayout from './feedlayout3';
import { useSelector } from 'react-redux';
import Product from './feedlayout';
// import Product from './feedWillPayWindow'; 
import { Rootstate } from '../../interface';
// import FeedLayout from './feedlayout'; 
import Menu2 from '../../components/menu2';
function Feed(props) {
    const profile = useSelector<Rootstate>(state=>state.profile)
    return (
        <div>
            <Menu />
            {(profile) ? <Product /> : <Profile />}
        </div>
    );
}

export default Feed;
