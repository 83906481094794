import React from 'react';
import { Card, Container, Button, Modal, Media, ProgressBar, Popover, OverlayTrigger } from 'react-bootstrap';
import SampleImage from '../../images/light-bulb.jpg';
import { useHistory } from 'react-router-dom';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import ProfileImage from '../../images/profile.jpg';
import ProfileImage1 from '../../images/profile1.jpg';
import ProfileImage2 from '../../images/profile2.png';
import SuccessImage from '../../images/check-mark.png';
import MenuBar from '../../images/menu.png';
import styles from './feed.module.css';
import { useDispatch } from 'react-redux';
import Visual from '../../components/visual';
import { switchProfile } from '../../redux-store/action';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Tab from '../../components/tab';
import Slider from '@material-ui/core/Slider';
import EmailCopy from '../../images/copy.png';
import { IconButton } from '@material-ui/core';
import { Equalizer, PermIdentity } from '@material-ui/icons';
import { useState, useEffect } from "react";
import '../price.css'
const useStyles = makeStyles({
    root: {
        width: 300,
    },
});

const muiTheme = createMuiTheme({
    overrides: {
        MuiSlider: {
            thumb: {
                color: "#6a7bff",
            },
            track: {
                color: '#6a7bff'
            },
            rail: {
                color: 'black'
            }
        }
    }
});

const marks = [
    {
        value: 0,
        label: '$0',
    },
    {
        value: 100,
        label: '$100',
    },
];

function valuetext(value) {
    return `${value}°C`;
}
function FeedLayout(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [willPay, SetWillPay] = React.useState(false);
    const [willNot, SetWillNot] = React.useState(false);
    const [submitted, SetSubmit] = React.useState(false);
    const [auth, setAuth] = React.useState<{ user?: any }>({});
    const history = useHistory();
    const [value, setValue] = React.useState([20]);
    const [selectedValue, setSelectedValue] = React.useState('Yes');
    const [show, setShow] = React.useState(false);
    const [chartVisible, SetChart] = React.useState(true);
    const handleClose = () => {
        setShow(false);
        SetSubmit(true);
        SetWillNot(false);
        SetWillPay(false);
    };
    const handleShow = () => setShow(true);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    //const [money,setMoney]=useState(100)
    //const moneyNav=document.getElementsByClassName('PrivateValueLabel-label-9') as HTMLCollectionOf<Element>;
    //if (moneyNav){
    //const y = + moneyNav[0].innerHTML;
    //const changeMoney=100-y
    //setMoney(changeMoney)
    //}
    //const [inputMoney, setInputMoney] = useState("100$");
    //const onMoneyChangeHandler = event => {
    //setInputMoney(event.target.value);
    //}



    const handleCheckChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const showWillPayOptions = () => {
        SetWillPay(true);
        SetWillNot(false);
        const money=document.getElementById('money') as HTMLElement;
        if(money){
            money.style.display='block';
        }
    };

    const showWillNotOptions = () => {
        SetWillNot(true);
        SetWillPay(false);
        const money=document.getElementById('money') as HTMLElement;
        if(money){
            money.style.display='none';
        }
    }

    const profileSwitcher = () => {
        dispatch(switchProfile(false));
    }
    const [inputComment, setInputComment] = useState("");
    const onChangeHandler = event => {
    setInputComment(event.target.value);
    }
    const [inputComment2, setInputComment2] = useState("");
    const onChangeHandler2 = event => {
    setInputComment2(event.target.value);
 }
    React.useEffect(() => {
        if (localStorage.getItem('profile')) {
            setAuth(JSON.parse(localStorage.getItem('profile')));
        } else {
            history.push('/');
        }
    }, []);

    var a ="\\00d7";
        const comments=document.getElementById('comments') as HTMLElement;
        const showcomments=document.getElementById('showcomments') as HTMLElement;
        if(showcomments){
            showcomments.addEventListener('click',function(){
                comments.style.display='block'
            })}
        
        const closecomments=document.getElementById('closecomments') as HTMLElement;
        if(closecomments){
            closecomments.addEventListener('click',function(){
                comments.style.display='none'
            })}
    
    



    const popOver = (
        <Popover id={`popover-basic`} style={{ width: 360, maxWidth: '100%' }}>
            <Popover.Content>
                <Media>
                    <img
                        width={64}
                        height={64}
                        className="align-self-start mr-3 rounded-circle"
                        src={ProfileImage}
                        alt="Generic placeholder"
                    />
                    <Media.Body>
                        <h5 className={styles.profileTitle}>Adam Lee</h5>
                        <p className={styles.profileDesc}>Music Enthusiast</p>
                        {/* <div className="d-flex">
                            <span className={"mr-auto " + styles.profileProgress}>ARR</span>
                            <span className={"ml-auto " + styles.profileProgress}>10 Mi</span>
                        </div>
                        <ProgressBar style={{ height: '0.3rem' }} now={60} /> */}
                        <div className="mt-3">
                            <button className={styles.profileButton}>My Creations</button>{' '}
                            <button className={styles.profileButton}>Timeline</button>{' '}
                            <button className={styles.profileButton}>Updates</button>
                        </div>
                    </Media.Body>
                </Media>
            </Popover.Content>
        </Popover>
    );

    return (
        <Container className="mt-3" >
            <div className="mx-auto" style={{marginTop:'50px'}}>
                
                <div id="comments" style={{boxShadow:'0 4px 8px 0 rgba(0,0,0,0.2)',transition:'0.3s',width:'30%',borderRadius:'5px',float:'right',padding:'10px',marginTop:'170px',display:'none'}}>
                        <p style={{textAlign:'center',fontWeight: 800,fontSize: '1vw',textTransform: 'uppercase',fontFamily:'ManMedium'}}>COMMENTS<svg id="closecomments" style={{width:'12px',height:'12px',float:'right',cursor:'pointer'}} version="1.1"
     xmlns="http://www.w3.org/2000/svg">
    <line x1="1" y1="11" 
          x2="11" y2="1" 
          stroke="black" 
          stroke-width="2"/>
    <line x1="1" y1="1" 
          x2="11" y2="11" 
          stroke="black" 
          stroke-width="2"/>
</svg></p>
                        <img src='https://pbs.twimg.com/profile_images/1503595584489668610/mHNfif7w_400x400.jpg' className={styles.feedProfile} /><div className="ml-auto float-right" style={{cursor:'pointer',transform: 'rotate(+45deg)'}}></div><p style={{textAlign:'left',fontSize:'15px',fontFamily:'ManMedium'}}>This idea is actually insane and helped me a lot.Keep going team</p>
                        <div style={{ height: 4,width:'auto',marginTop:'12px', backgroundColor: '#3F51B5',marginBottom:'10px'  }}></div>
                        <img src={ProfileImage1} className={styles.feedProfile} /><div className="ml-auto float-right" style={{cursor:'pointer',transform: 'rotate(+45deg)'}}></div><p style={{textAlign:'left',fontSize:'15px',fontFamily:'ManMedium'}}>Clean and simple to understand.Great work👏</p>
                        <div style={{ height: 4,width:'auto',marginTop:'14px', backgroundColor: '#3F51B5',marginBottom:'10px' }}></div>
                        <img src={ProfileImage2} className={styles.feedProfile} /><div className="ml-auto float-right" style={{cursor:'pointer',transform: 'rotate(+45deg)'}}></div><p style={{textAlign:'left',fontSize:'15px',fontFamily:'ManMedium'}}>Looking forward on how this project goes</p>
                        <div style={{ height: 4,width:'auto',marginTop:'12px', backgroundColor: '#3F51B5',marginBottom:'10px'}}></div>
                        </div>
                <p style={{marginLeft:'320px',fontFamily:'ManMedium',marginTop:'50px'}}>19 July,2022</p>
                <Card className={"mx-auto mt-3 mb-3 " + styles.profileCard} >
                    <Card.Header className={"d-flex align-items-center " + styles.feedCard}>
                        <div style={{display:'flex'}}>
                            <img style={{width:'64px',height:'64px',cursor:'pointer',borderRadius:'50%',alignItems:'center'}} onClick={profileSwitcher} src='https://pbs.twimg.com/profile_images/1503595584489668610/mHNfif7w_400x400.jpg' className={styles.feedProfile} />

                        </div>
                        {(chartVisible) ? <div className="ml-auto"><IconButton onClick={() => { SetChart(false) }}><Equalizer /></IconButton></div> : <div className="ml-auto" onClick={() => { SetChart(true) }} style={{ cursor: 'pointer' }}><img src={MenuBar} style={{ width: 24 }} /></div>}
                        <div  style={{cursor:'pointer',transform: 'rotate(+45deg)',display:'none'}}><p className="card-price">$120</p></div>
                    </Card.Header>
                    {(chartVisible) ? <div>
                        <Card.Img variant="top" src={SampleImage} />
                        <Card.Body>
                            <Card.Title className={styles.feedCard}>rock-o-band</Card.Title>
                            <Card.Text className={styles.feedDesc}>
                                I am a very simple card. I am good at containing small bits of information. I am convenient because I require little markup to use effectively.
                            </Card.Text>
                            <div>
                                <span className={styles.tags}>IT</span>
                                <span className={styles.tags}>SaaS</span>
                                <span className={styles.tags}>Movies</span>
                            </div>
                        </Card.Body>
                        <div id="myNav" className="overlay">
                        <div style={{boxShadow:'0 4px 8px 0 rgba(0,0,0,0.2)',transition:'0.3s',width:'40%',borderRadius:'5px',margin:'auto',padding:'10px'}}>
                        <p style={{textAlign:'center',fontWeight: 800,fontSize: '1vw',textTransform: 'uppercase',fontFamily:'ManMedium',color:'white'}}>COMMENTS<svg  style={{width:'12px',height:'12px',float:'right',cursor:'pointer'}} version="1.1" fill='indigo'
                            xmlns="http://www.w3.org/2000/svg">
                            <line x1="1" y1="11" 
                                x2="11" y2="1" 
                                stroke="black" 
                                stroke-width="2"/>
                            <line x1="1" y1="1" 
                                x2="11" y2="11" 
                                stroke="black" 
                                stroke-width="2"/>
                        </svg></p>
                        <img src='https://pbs.twimg.com/profile_images/1503595584489668610/mHNfif7w_400x400.jpg' className={styles.feedProfile} /><div className="ml-auto float-right" style={{cursor:'pointer'}}></div><p style={{textAlign:'left',fontSize:'15px',fontFamily:'ManMedium',color:'white'}}>This idea is actually insane and helped me a lot.Keep going team</p>
                        <div style={{ height: 4,width:'auto',marginTop:'12px', backgroundColor: '#3F51B5',marginBottom:'10px'  }}></div>
                        <img src={ProfileImage1} className={styles.feedProfile} /><div className="ml-auto float-right" style={{cursor:'pointer'}}></div><p style={{textAlign:'left',fontSize:'15px',fontFamily:'ManMedium',color:'white'}}>Clean and simple to understand.Great work👏</p>
                        <div style={{ height: 4,width:'auto',marginTop:'14px', backgroundColor: '#3F51B5',marginBottom:'10px' }}></div>
                        <img src={ProfileImage2} className={styles.feedProfile} /><div className="ml-auto float-right" style={{cursor:'pointer'}}></div><p style={{textAlign:'left',fontSize:'15px',fontFamily:'ManMedium',color:'white'}}>Looking forward on how this project goes</p>
                        <div style={{ height: 4,width:'auto',marginTop:'12px', backgroundColor: '#3F51B5',marginBottom:'10px'}}></div>
                        </div>
                        </div>
                        {(submitted === false) ? <Card.Body className="d-flex">
                            <button className={styles.willpay + " mr-auto"} onClick={showWillPayOptions}>WILL PAY</button>
                            <button className={styles.willnot} onClick={showWillNotOptions}>WILL NOT</button>
                        </Card.Body> : <div>
                            <div className="text-center">
                                <img src={SuccessImage} />
                                <p className={"mt-2 " + styles.yourFeedbackTitle}>Your Feedback is submitted</p>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: '60%' }}>
                                    <p className={styles.willNotColor + " pl-1"}>60%</p>
                                    <div style={{ height: 4, backgroundColor: '#B84B4B' }}></div>
                                </div>
                                <div style={{ width: '40%' }} className="text-right">
                                    <p className={styles.willPayColor + " pr-1"}>40%</p>
                                    <div style={{ height: 4, backgroundColor: '#54A538' }}></div>
                                </div>
                            </div>
                        </div>}

                        {willPay ? <Card.Body className="mt-4">
                            <ThemeProvider theme={muiTheme}>
                                <Slider
                                    value={value}
                                    onChange={handleChange}
                                    aria-labelledby="range-slider"
                                    getAriaValueText={valuetext}
                                    valueLabelDisplay="on"
                                    marks={marks}
                                />
                                <input value={inputComment} onChange={onChangeHandler} className={"ml-auto form-control " + styles.homeInput} type="text" placeholder="comments"  />
                                <p className={"mt-3 " + styles.creatorFont}>Do you want the creator to get in touch with you directly?</p>
                                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                    <FormControlLabel
                                        value="Yes"
                                        control={<Radio color="primary" checked={selectedValue === 'Yes'} />}
                                        onChange={handleCheckChange}
                                        className={styles.creatorFont}
                                        label="Yes"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        value="No"
                                        control={<Radio color="primary" checked={selectedValue === 'No'} />}
                                        onChange={handleCheckChange}
                                        label="No"
                                        className={styles.creatorFont}
                                        labelPlacement="start"
                                    />
                                </RadioGroup>
                                <div className="text-center">
                                    <button className={styles.TabActive} onClick={handleShow}>THANK YOU</button>
                                </div>
                            </ThemeProvider>
                        </Card.Body> : null}
                        {willNot ? <Card.Body>
                            <input value={inputComment} onChange={onChangeHandler} className={"ml-auto mb-3 form-control " + styles.homeInput} type="text" placeholder="Help us Understand...Why?" />
                            <input value={inputComment2} onChange={onChangeHandler2} className={"ml-auto form-control " + styles.homeInput} type="email" placeholder="EMAIL ID (optional for creator to contact)" />
                            <div className="text-center mt-3">
                                <button className={styles.TabActive}>SEND FEEDBACK</button>
                            </div>
                        </Card.Body> : null}
                    </div> : <Card.Body>
                        <Visual />
                    </Card.Body>}
                    <div style={{display:'flex',margin:'auto',width:'auto',marginTop:'10px',marginBottom:'5px'}}>
                    <img  style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center',marginLeft:'-210px'}} onClick={profileSwitcher} src='https://pbs.twimg.com/profile_images/1503595584489668610/mHNfif7w_400x400.jpg' className={styles.feedProfile} />
                            
                            <img style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center',marginLeft:'5px'}} onClick={profileSwitcher} src={ProfileImage1} className="gavatar" />&nbsp;
                            <img style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center'}} onClick={profileSwitcher} src={ProfileImage2} className="avatar" />&nbsp;
                            <img style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center'}} onClick={profileSwitcher} src={ProfileImage1} className="avatar" />&nbsp;
                            <img style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center'}} onClick={profileSwitcher} src={ProfileImage1} className="gavatar" />&nbsp;
                            <img style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center'}} onClick={profileSwitcher} src={ProfileImage1} className="gavatar" />&nbsp;
                            
                            

                            
                            
                        </div>
                </Card>
                
            </div>

            
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body className="text-center p-4">
                    <p className={styles.feedModalHeader}>Thank you for providing your feedback!</p>
                    <p className={styles.feedModalDesc}>Copy the creators email below!</p>
                    <span className={styles.feedModalEmailCopy}><img src={EmailCopy} className={styles.feedEmailLogo} /> sriharsha19mel@gmail.com</span>
                    <div className="text-center mt-3">
                        <button className={styles.TabActive} onClick={handleClose}>FEED</button>
                    </div>
                </Modal.Body>
            </Modal>
            <div className="text-center" id="money" style={{float:'right',marginTop:'-330px',marginRight:'200px',display:"none"}}>
                                    <button style={{width:'50px',justifyContent:'center'}}className={styles.TabActive} >{100-value[0]}</button>
                                </div>
        </Container>
    );
}

export default FeedLayout;
