import React from 'react';
import moment from 'moment';
import styles from './custom.module.css';
import { makeStyles } from '@material-ui/core/styles';
import { Row, Col } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexwrap: 'wrap'
    },
    formControl: {
        minWidth: '100%'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200
    }
}))

const BookSlot = ({ date, bookSlotHandler }) => {
    const classes = useStyles();
    const datetime = moment(date).format('MMMM Do YYYY');
    const [age, setAge] = React.useState("");
    const [slotValue, setInputSlot] = React.useState("");

    const inputChangeHandler = (e) => {
        setInputSlot(e.target.value);
    }
    const book = () => {
        bookSlotHandler();
    }

    const handleChange = (event) => {
        setAge(event.target.value);
    }
    return (
        <div className="mt-2">
            <p className={"text-center " + styles.cardDesc}>{datetime}</p>
            <Row>
                <Col>
                    <TextField
                        id="time"
                        label="Available Slot"
                        type="time"
                        defaultValue="07:30"
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                    />
                </Col>
                <Col>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Duration</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={age}
                            onChange={handleChange}
                        >
                            <MenuItem value={10}>5 Minutes</MenuItem>
                            <MenuItem value={20}>10 Minutes</MenuItem>
                            <MenuItem value={30}>15 Minutes</MenuItem>
                            <MenuItem value={40}>20 Minutes</MenuItem>
                            <MenuItem value={50}>25 Minutes</MenuItem>
                            <MenuItem value={60}>30 Minutes</MenuItem>
                        </Select>
                    </FormControl>
                </Col>
            </Row>
            <input value={slotValue || ""} onChange={inputChangeHandler} className={"ml-auto mt-3 form-control " + styles.homeInput} type="text" placeholder="What do we talk about?" />
            <div className="d-flex justify-content-center">
                <button className={styles.buttonDefault} onClick={book}>Book a slot</button>
            </div>
        </div>
    )
}

export default BookSlot;