import React from 'react';
import { Col, Row, Card, Container } from 'react-bootstrap';
import styles from './login.module.css';
import NewLogo from '../../images/logo.png';
import { singleUser } from '../../redux-store/action';
import { useSelector, useDispatch } from 'react-redux';
import { firestore } from '../../auth/firebase';
import Backdrop from '@material-ui/core/Backdrop';
import { Redirect, useHistory } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import GoogleLogin from 'react-google-login';
import twitterIcon from '../../images/twitter.png';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

function LoginScreen(props) {

    const dispatch = useDispatch();
    const result = (res) => {
        dispatch(singleUser(res));
    };
    const simpleValidator = React.useRef(new SimpleReactValidator());
    const history = useHistory();
    const [user, setUser] = React.useState(JSON.parse(localStorage.getItem('profile')));
    const [open, setOpen] = React.useState(false);

    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff'
        }
    }))

    const classes = useStyles();

    const responseGoogle = async (response) => {
        setOpen(true);
        const token = response?.tokenId;
        try {
            const res = await fetch("https://willpay.herokuapp.com/api/v1/auth/google", {
                method: "POST",
                body: JSON.stringify({
                    token
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })

            const data = await res.json();
            if (data) {
                dispatch({ type: 'AUTH', data: { token: data.token, user: data.user } });
                history.push('/feed');
            }
        } catch (error) {
            console.log(error);
        }
    }


    const responseGoogleFailure = (response) => {
        console.log(response);
    }

    const handleClose = () => {
        setOpen(!open);
    }

    if (user) {
        return (<Redirect to="/feed" />);
    } else {
        return (
            <div>
                <Backdrop className={classes.backdrop} open={open}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Container>
                    <Row className="no-gutters">
                        <Col sm={12}>
                            <Card className={"mx-auto mt-3 mb-5 " + styles.landingCard}>
                                <div className="d-flex" style={{ padding: '1.25rem' }}>
                                    <img src={NewLogo} alt="newlogo" className="ml-auto" style={{ width: 148 }} />
                                </div>
                                <Card.Body className="text-center">
                                    <div>
                                        <GoogleLogin
                                            className="mb-3"
                                            clientId="946942732958-jtpictl2dpdttpm6bd375t1q6binv7pp.apps.googleusercontent.com"
                                            buttonText="Sign-in with Google"
                                            onSuccess={responseGoogle}
                                            onFailure={responseGoogleFailure}
                                            cookiePolicy={'single_host_origin'}
                                        />
                                    </div>
                                    {/* <div>
                                        <GoogleLogin
                                            className="mb-3"
                                            clientId="946942732958-jtpictl2dpdttpm6bd375t1q6binv7pp.apps.googleusercontent.com"
                                            render={renderProps =>(
                                                <button onClick={renderProps.onClick} disabled={renderProps.disabled} className={styles.twitterButton}><img src={twitterIcon} alt="twitter" style={{marginRight:5,width:21}} /> Login with Twitter</button>
                                            )}
                                            buttonText="Sign-in with Google"
                                            onSuccess={responseGoogle}
                                            onFailure={responseGoogleFailure}
                                            cookiePolicy={'single_host_origin'}
                                        />
                                    </div> */}
                                </Card.Body>
                                {/* <Card.Body>
                                    <div>
                                        <label htmlFor="email" >Email</label>
                                        <input value={userInput.email || ""} type="email" id="username" onChange={inputHandler} className={"form-control mb-3 " + styles.loginInput} name="email" placeholder="Email" />
                                        <label htmlFor="password" >Password</label>
                                        <input value={userInput.password || ""} type="password" id="password" onChange={inputHandler} className={"form-control mb-3 " + styles.loginInput} name="password" placeholder="Password" />
                                        <button className={styles.regular} onClick={loginSave}>SUBMIT</button></div>
                                </Card.Body> */}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default LoginScreen;
