import React from 'react';
import { useSelector } from 'react-redux';
import CardMedia from '@material-ui/core/CardMedia';
import { Rootstate } from '../../interface';
import carIcon from '../../images/car.jpg';
import Card from '@material-ui/core/Card';
import Highcharts, { chart } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import styles from './goal.module.css';
import Switch from '@material-ui/core/Switch';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, withStyles } from '@material-ui/core';
import userIcon from '../../images/user.svg';
import { Col, Row } from 'react-bootstrap';
import dashboardIcon from '../../images/dashboard.png';
import { BarChart, Person } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    media: {
        height: 250,
        marginTop: 20
    },
    goalLine: {
        marginTop: 10,
        marginBottom: 5,
        fontSize: 14
    },
    content: {
        display: 'flex',
        marginBottom: 0,
        '&: last-child': {
            paddingBottom: 0
        },
        backgroundColor: '#f7f7f7'
    },
    goalRow: {
        marginTop: 10
    }
}))

const GoalFlag = (props) => {
    const classes = useStyles();
    const [checkedC, setSwitch] = React.useState(false);
    const options = {
        chart: {
            type: 'area',
            height: 320
        },
        legend: {
            enabled: false
        },
        title: {
            text: ''
        },
        xAxis: {
            title: {
                text: 'timeline'
            },
            categories: ['Jan-2021', 'Feb-2021', 'Mar-2021', 'Apr-2021', 'May-2021', 'Jun-2021', 'Jul-2021', 'Aug-2021', 'Sep-2021', 'Oct-2021', 'Nov-2021', 'Dec-2021']
        },
        yAxis: {
            title: {
                text: 'Monthly Recurring Revenue'
            },
            gridLineColor: 'transparent'
        },
        series: [
            {
                data: [0, 1000, 2300, 3200, 4300, 5230, 6740, 7320, 8400, 9550, 9600, 9900]
            }
        ]
    };
    return (
        <>
            {(props.showGraph) ? <HighchartsReact
                highcharts={Highcharts}
                options={options}
            /> :
                <CardMedia
                    className={classes.media}
                    image={carIcon}
                    title="Contemplative Reptile"
                />}
            <p className={classes.goalLine}>My Target is to buy Mustang GT priced $AU 64,390 by March 2023.</p>
            <Row className={classes.goalRow}>
                <Col sm={4}>
                    <CardContent className={classes.content}>
                        {/* <p className={styles.statsContent}><img src={dashboardIcon} style={{ width: 28 }} /></p> */}
                        <BarChart color="action" />
                        <div className={styles.statsContent + " ml-auto"}>
                            <span className={styles.statsTitle}>MRR</span>
                            <p className={"mb-0 "+styles.statsValue}>AU 1200</p>
                        </div>
                    </CardContent>
                </Col>
                <Col sm={4}>
                    <CardContent className={classes.content}>
                        <BarChart color="action" />
                        <div className={styles.statsContent + " ml-auto"}>
                            <span className={styles.statsTitle}>ARR</span>
                            <p className={"mb-0 "+styles.statsValue}>AU 1200</p>
                        </div>
                    </CardContent>
                </Col>
                <Col sm={4}>
                    <CardContent className={classes.content}>
                        <Person color="action" />
                        <div className={styles.statsContent + " ml-auto"}>
                            <span className={styles.statsTitle}>Cust.</span>
                            <p className={"mb-0 "+styles.statsValue}>600</p>
                        </div>
                    </CardContent>
                </Col>
            </Row>
        </>
    )
}

export default GoalFlag;