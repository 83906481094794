import React from 'react';
import { Container, Button, Modal, Row, Col } from 'react-bootstrap';
import Menu from '../../components/menu';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import styles from './goal.module.css';
import Switch from '@material-ui/core/Switch';
import CardContent from '@material-ui/core/CardContent';
import returnLogo from '../../images/left-arrow.png';
import Highcharts, { chart } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import carIcon from '../../images/car.jpg';
import dashboardIcon from '../../images/dashboard.png';
import userIcon from '../../images/user.svg';
import { Link } from 'react-router-dom';
import { CardMedia } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        minWidth: 345,
    },
    media: {
        height: 480,
    },
    content: {
        display: 'flex'
    }
});


const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
        marginLeft: 'auto'
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

function Goal(props) {
    const classes = useStyles();
    const options = {
        chart: {
            type: 'area',
            height: 480
        },
        legend: {
            enabled: false
        },
        title: {
            text: ''
        },
        xAxis: {
            title: {
                text: 'timeline'
            },
            categories: ['Jan-2021', 'Feb-2021', 'Mar-2021', 'Apr-2021', 'May-2021', 'Jun-2021', 'Jul-2021', 'Aug-2021', 'Sep-2021', 'Oct-2021', 'Nov-2021', 'Dec-2021']
        },
        yAxis: {
            title: {
                text: 'Monthly Recurring Revenue'
            },
            gridLineColor: 'transparent'
        },
        series: [
            {
                data: [0, 1000, 2300, 3200, 4300, 5230, 6740, 7320, 8400, 9550, 9600, 9900]
            }
        ]
    };
    const [checkedC, setSwitch] = React.useState(false);
    const handleChange = () => {
        setSwitch(!checkedC);
    }

    React.useEffect(() => {

    }, []);

    return (
        <div>
            <Menu />
            <Container className="mt-5">
                <div className="mb-2">
                    <Link to="/feed" className={styles.goBack} style={{ fontSize: 21 }}><img src={returnLogo} style={{ width: 32 }} />Back</Link>
                </div>
                <Row>
                    <Col className="mx-auto" sm={12}>
                        <Card className={classes.root}>
                            <CardContent>
                                <div className="d-flex mb-3 align-items-center">
                                    <p className={styles.statsTitle}>My Target is to buy Mustang GT priced $AU 64,390 by March 2023.</p>
                                    <AntSwitch checked={checkedC} onChange={handleChange} name="checkedC" />
                                </div>
                                {(checkedC)?<HighchartsReact
                                    highcharts={Highcharts}
                                    options={options}
                                />:
                                <CardMedia
                                    className={classes.media}
                                    image={carIcon}
                                    title="Contemplative Reptile"
                                />}
                            </CardContent>
                        </Card>
                        <div className="mt-2"></div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col sm={4}>
                        <Card className={classes.root}>
                            <CardContent className={classes.content}>
                                <p className={styles.statsContent}><img src={dashboardIcon} style={{ width: 28 }} /> MRR</p>
                                <p className={styles.statsContent + " ml-auto"}>$AU1200</p>
                            </CardContent>
                        </Card>
                    </Col>
                    <Col sm={4}>
                        <Card className={classes.root}>
                            <CardContent className={classes.content}>
                                <p className={styles.statsContent}><img src={dashboardIcon} style={{ width: 28 }} /> ARR</p>
                                <p className={styles.statsContent + " ml-auto"}>$AU14400</p>
                            </CardContent>
                        </Card>
                    </Col>
                    <Col sm={4}>
                        <Card className={classes.root}>
                            <CardContent className={classes.content}>
                                <p className={styles.statsContent}><img src={userIcon} style={{ width: 28 }} /> Customers</p>
                                <p className={styles.statsContent + " ml-auto"}>600</p>
                            </CardContent>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Goal;
