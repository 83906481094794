import React from 'react';
import { useSelector } from 'react-redux';
import FeedbackList from '../feedlist';
import CreateFeedback from '../createFeedback';
import { Rootstate } from '../../interface';

const Feedback = (props) => {
    const feedbackList = useSelector((state: Rootstate) => state.isFeedBackList);

    return (
        <>
            {(feedbackList) ? <FeedbackList />: <CreateFeedback />}
        </>
    )
}

export default Feedback;