import './App.css';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import Feed from './screens/feed/feed';
import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { authUser } from './redux-store/action';
import { useSelector, useDispatch } from 'react-redux';
// import PrivateRoute from './auth/privateroute';
import Submission from './screens/submission';
import ReactGA from 'react-ga';
import Creations from './screens/creations';
import LoginScreen from './screens/login/loginscreen';
import Profiles from './screens/profile/profile';
import HomeScreen from './screens/home';
import { createBrowserHistory } from 'history';
import FeedWillPayWindow from './screens/feed/feedWillPayWindow';
import Goal from './screens/goal/goal';
import Create from './screens/create';
import { Rootstate } from './interface';
import { useState } from 'react';
import Earned from './screens/earned';
import Spent from './screens/spent';
const cookies = new Cookies();

function App() {
  const authtoken = useSelector((state: Rootstate) => state.auth);
  const dispatch = useDispatch();
  const history = createBrowserHistory();

  history.listen(location => {
    ReactGA.set({page: location.location.pathname});
    ReactGA.pageview(location.location.pathname);
  })

  useEffect(() => {
    if (cookies.get('auth')) {
      dispatch(authUser(true));
    }
    else {
      dispatch(authUser(false));
    }
  }, [dispatch])
  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          {/* {authtoken ?<HomeScreen />:<Feed />} */}
          {/* {authtoken ?<Feed />:<HomeScreen/>} */}
          {authtoken ?<Feed />:<FeedWillPayWindow />}
        </Route>
        <Route exact path="/demo" component={LoginScreen} />
        <Route exact path='/feed' component={Feed}/>
        <Route exact path="/demo" component={LoginScreen} />
        <Route exact path='/mine' component={Submission}/>
        <Route exact path='/creations' component={Creations}/>
        <Route exact path='/goals' component={Goal}/>
        <Route exact path='/create' component={Create}/>
        <Route exact path='/profile' component={Profiles}/>
        <Route exact path='/earned' component={Earned}/>
        <Route exact path='/spent' component={Spent}/>

      </Switch>
    </Router>
  );
}

export default App;
