const initialState = {
    user: {},
    auth: false,
    authData: null,
    profile: true,
    isFeedBackList: false
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case "SINGLE_USER":
            return {
                ...state,
                user: action.token
            };
        case "AUTHORIZATION":
            return {
                ...state,
                auth: action.token
            };
        case "AUTH":
            localStorage.setItem('profile', JSON.stringify(action.data));
            return {
                ...state,
                authData: action.data
            };
        case "SWITCH_PROFILE":
            return {
                ...state,
                profile: action.token
            };
        case "SWITCH_FEEDBACK":
            return {
                ...state,
                isFeedBackList: action.token
            };
        default:
            return state;
    }
}

export default reducer;