import React from 'react';
import { Card, Container } from 'react-bootstrap';
import SampleImage from '../images/light-bulb.jpg';
import ProfileImage from '../images/profile.jpg';
import styles from './feed/feed.module.css';
import Tab from '../components/tab';
import Menu from '../components/menu';

function Submission() {
    // const [willPay, SetWillPay] = React.useState(false);
    // const [willNot, SetWillNot] = React.useState(false);
    // const [submitted, SetSubmit] = React.useState(false);
    // const [value, setValue] = React.useState([20, 37]);
    // const [selectedValue, setSelectedValue] = React.useState('Yes');
    // const [show, setShow] = React.useState(false);
    // const handleClose = () => {
    //     setShow(false);
    //     SetSubmit(true);
    //     SetWillNot(false);
    //     SetWillPay(false);
    // };
    // const handleShow = () => setShow(true);
    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };

    // const handleCheckChange = (event) => {
    //     setSelectedValue(event.target.value);
    // };

    // const showWillPayOptions = () => {
    //     SetWillPay(true);
    //     SetWillNot(false);
    // };

    // const showWillNotOptions = () => {
    //     SetWillNot(true);
    //     SetWillPay(false);
    // }
    return (
        <div>
            <Menu />
            <Container className="mt-3">
                <div className="mx-auto">
                    <Tab />
                    <Card className = {"mx-auto mt-3 mb-3 "+styles.profileCard}>
                        <Card.Header className={"d-flex align-items-center " + styles.feedCard}><img src={ProfileImage} alt="" className={styles.feedProfile} /><span className="ml-2">DMatiz</span><div></div></Card.Header>
                        <Card.Img variant="top" alt="" src={SampleImage} />
                        <Card.Body>
                            <Card.Title className={styles.feedCard}>rock-o-band</Card.Title>
                            <Card.Text className={styles.feedDesc}>
                                I am a very simple card. I am good at containing small bits of information. I am convenient because I require little markup to use effectively.
                        </Card.Text>
                            <div>
                                <span className={styles.tags}>IT</span>
                                <span className={styles.tags}>SaaS</span>
                                <span className={styles.tags}>Movies</span>
                            </div>
                        </Card.Body>
                        <div>
                            <div className="d-flex">
                                <div style={{ width: '60%' }}>
                                    <p className={styles.customDisable + " pl-1"}>60%</p>
                                    <div style={{ height: 4, backgroundColor: '#747474' }}></div>
                                </div>
                                <div style={{ width: '40%' }} className="text-right">
                                    <p className={styles.willPayColor + " pr-1"}>40%</p>
                                    <div style={{ height: 4, backgroundColor: '#54A538' }}></div>
                                </div>
                            </div>
                        </div>
                    </Card>
                    <Card className = {"mx-auto mt-3 mb-5 "+styles.profileCard}>
                        <Card.Header className={"d-flex align-items-center " + styles.feedCard}><img src={ProfileImage} alt="" className={styles.feedProfile} /><span className="ml-2">DMatiz</span><div></div></Card.Header>
                        <Card.Img variant="top" alt="" src={SampleImage} />
                        <Card.Body>
                            <Card.Title className={styles.feedCard}>rock-o-band</Card.Title>
                            <Card.Text className={styles.feedDesc}>
                                I am a very simple card. I am good at containing small bits of information. I am convenient because I require little markup to use effectively.
                        </Card.Text>
                            <div>
                                <span className={styles.tags}>IT</span>
                                <span className={styles.tags}>SaaS</span>
                                <span className={styles.tags}>Movies</span>
                            </div>
                        </Card.Body>
                        <div>
                            <div className="d-flex">
                                <div style={{ width: '20%' }}>
                                    <p className={styles.willNotColor + " pl-1"}>20%</p>
                                    <div style={{ height: 4, backgroundColor: '#B84B4B' }}></div>
                                </div>
                                <div style={{ width: '80%' }} className="text-right">
                                    <p className={styles.customDisable + " pr-1"}>80%</p>
                                    <div style={{ height: 4, backgroundColor: '#747474' }}></div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </Container>
        </div>
    );
}

export default Submission;
