import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CompanyImage1 from '../../images/company1.png';
import CompanyImage2 from '../../images/company2.png';
import CompanyImage3 from '../../images/company3.png';
import CompanyImage4 from '../../images/company4.png';
import CompanyImage5 from '../../images/slack.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { switchProfile, switchFeedback } from '../../redux-store/action';
import PlusImage from '../../images/plus.png';
import BackImage from '../../images/back-arrow.png';
import Switch from '@material-ui/core/Switch';
import IndianFlag from '../../images/india.png';
import customStyles from './custom.module.css';
import Calendar from 'react-calendar';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from 'react-router-dom';
import CardContent from '@material-ui/core/CardContent';
import 'react-calendar/dist/Calendar.css';
import BookSlot from './bookslot';
import './custom.css';
import { Equalizer, ListAlt, MoreHoriz, PermIdentity, FlagOutlined, Flag } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Checkbox, createMuiTheme, FormControlLabel, IconButton, ListItemSecondaryAction, Typography, MuiThemeProvider, StylesProvider } from '@material-ui/core';
import Visual from '../../components/visual';
import ProductBar from '../../components/productbar';
import Feedback from '../../components/feedback/feedback';
import MenuBar from '../../images/menu.png';
import FeedbackIcon from '@material-ui/icons/Feedback';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { Rootstate } from '../../interface';
import GoalFlag from '../../components/goalFlag';
import Goal from '../goal/goal';

const useStyles = makeStyles({
    root: {
        minHeight: 480,
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: '1.5rem'
    },
    rounded: {
        borderRadius: 30,
        width: 64,
        marginRight: 'auto'
    },
    profile: {
        borderRadius: 30,
        width: 48,
        marginRight: 'auto'
    },
    spacing: {
        paddingTop: '1.25rem'
    },
    templateSwitch: {
        margin: 0
    }
});

function FeedLayout(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [auth, setAuth] = React.useState<{ user?: any }>({});
    const [calendarValue, onCalendarChange] = React.useState(new Date());
    const [isCalendar, setCalendarFlag] = React.useState(true);
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [switchProfiler, setSwitchProfile] = React.useState("1");
    const [slotPage, setSlotPage] = React.useState(false);
    const FeedbackList = useSelector<Rootstate>(state => state.isFeedBackList);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [check, setSettingsMenuCheck] = React.useState<{ checkedB?: boolean, checkedA?: boolean, checkedC?: boolean, checkedD?: boolean }>({
        checkedA: false
    })
    const [isGraph, setGraph] = React.useState(false);

    const theme = createMuiTheme({
        typography: {
            fontFamily: 'NunitoSemi'
        }
    });

    const profileSwitcher = () => {
        dispatch(switchProfile(true));
    }

    const [checked, SetChecked] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedC: true,
        checkedD: true
    })

    const handleChange = (event) => {
        SetChecked({ ...checked, [event.target.name]: event.target.checked });
        if (checked.checkedC) {
            setSwitchProfile("2");
        } else {
            setSwitchProfile("1");
        }
    }

    const handleGraphChange = () => {
        setGraph(!isGraph);
    }

    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.common.white,
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    const bookASlot = () => {

        setCalendarFlag(true);
        setSwitchProfile("1");
        setSlotPage(false);
        SetChecked({ ...checked, checkedC: true });
    }

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    }

    const handleSettingMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleSettingClose = (event) => {
        setAnchorEl(null);
    }

    const handleSettingsChange = (event) => {
        setSettingsMenuCheck({ ...check, [event.target.name]: event.target.checked });
    }

    React.useEffect(() => {

        const earned=document.getElementById('earned') as HTMLElement;
        const showearned=document.getElementById('showearned') as HTMLElement;
        earned.addEventListener('click',function(){
            showearned.style.display='block'
            showspent.style.display='none'
        })
        const spent=document.getElementById('spent') as HTMLElement;
        const showspent=document.getElementById('showspent') as HTMLElement;
        spent.addEventListener('click',function(){
            showspent.style.display='block'
            showearned.style.display='none'
        })


        if (localStorage.getItem('profile')) {
            setAuth(JSON.parse(localStorage.getItem('profile')));
        } else {
            history.push('/');
        }
    }, []);
//{(Object.keys(auth).length > 0) ? auth.user.profile_image : ""}
    return (
        <Container className="mt-3">
            <div className="mx-auto" style={{marginTop:'50px'}}>
                {/* <Tab /> */}
                <Card className={classes.root+" "+customStyles.profileCard}>
                    <CardContent>
                        <div className="d-flex">
                            <div className="d-flex align-items-center">
                            
                                <img  alt="profile" className={(switchProfiler === "1") ? classes.rounded : classes.profile} src='https://pbs.twimg.com/profile_images/1503595584489668610/mHNfif7w_400x400.jpg' />
                                {(switchProfiler === "1") ? null : <p className={customStyles.cardAnotherTitle}>{(Object.keys(auth).length > 0) ? auth.user.username : ""}</p>}
                            </div>
                            <div className="ml-auto align-self-center">
                            
                                <IconButton
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={handleSettingMenu}
                                >
                                    <MoreHoriz />
                                </IconButton>
                                {(check.checkedA && switchProfiler === "1") &&
                                    <IconButton
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={() => { setSwitchProfile("2") }}
                                    >
                                        <DateRangeIcon className={customStyles.feedbackColor} />
                                    </IconButton>}
                                {(check.checkedA && switchProfiler === "2") &&
                                    <IconButton
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={() => { setSwitchProfile("1") }}
                                    >
                                        <PermIdentity className={customStyles.feedbackColor} />
                                    </IconButton>}
                                <MuiThemeProvider theme={theme}>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleSettingClose}
                                    >
                                        <MenuItem>
                                            <FormControlLabel
                                                className={classes.templateSwitch}
                                                control={<Checkbox checked={check.checkedA || false} onChange={handleSettingsChange} name="checkedA" />}
                                                label={<Typography>Calendar</Typography>}
                                            />
                                        </MenuItem>
                                        <MenuItem>
                                            <FormControlLabel
                                                className={classes.templateSwitch}
                                                control={<Checkbox checked={check.checkedB || false} onChange={handleSettingsChange} name="checkedB" />}
                                                label={<Typography>Charts</Typography>}
                                            />
                                        </MenuItem>
                                        <MenuItem>
                                            <FormControlLabel
                                                className={classes.templateSwitch}
                                                control={<Checkbox checked={check.checkedC || false} onChange={handleSettingsChange} name="checkedC" />}
                                                label={<Typography>Feedback</Typography>}
                                            />
                                        </MenuItem>
                                        <MenuItem>
                                            <FormControlLabel
                                                className={classes.templateSwitch}
                                                control={<Checkbox checked={check.checkedD || false} onChange={handleSettingsChange} name="checkedD" />}
                                                label={<Typography>Goal</Typography>}
                                            />
                                        </MenuItem>
                                    </Menu>
                                </MuiThemeProvider>
                                {(check.checkedB && switchProfiler === "1") &&
                                    <IconButton
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={() => { setSwitchProfile("3") }}
                                    >
                                        <Equalizer className={customStyles.feedbackColor} />
                                    </IconButton>
                                }
                                {(check.checkedB && switchProfiler === "3") &&
                                    <IconButton
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={() => { setSwitchProfile("1") }}
                                    >
                                        <PermIdentity className={customStyles.feedbackColor} />
                                    </IconButton>
                                }

                                {(check.checkedC && switchProfiler === "1") ?
                                    <IconButton
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={() => { setSwitchProfile("4") }}
                                    >
                                        <FeedbackIcon className={customStyles.feedbackColor} />
                                    </IconButton> : null}

                                {(check.checkedC && switchProfiler === "4") ?
                                    <IconButton
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={() => { setSwitchProfile("1") }}
                                    >
                                        <PermIdentity className={customStyles.feedbackColor} />
                                    </IconButton> : null}

                                {(check.checkedD && switchProfiler === "1") ?
                                    <IconButton
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={() => { setSwitchProfile("5") }}
                                    >
                                        <FlagOutlined className={customStyles.feedbackColor} />
                                    </IconButton> : null}

                                {(check.checkedD && switchProfiler === "5") ?
                                    <IconButton
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={() => { setSwitchProfile("1") }}
                                    >
                                        <PermIdentity className={customStyles.feedbackColor} />
                                    </IconButton> : null}


                                {(check.checkedD && switchProfiler === "5") ?
                                    <span style={{padding:14}}>
                                    <AntSwitch checked={isGraph} onChange={handleGraphChange} name="isGraph" /> </span>: null}
                                {/* {(switchProfiler === "4" && FeedbackList === true) ?
                                <IconButton
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={() => {dispatch(switchFeedback(false))}}
                                >
                                    <ListAlt className={classes.feedbackColor} />
                                </IconButton> : null} */}

                                {/* {(switchProfiler === "4" && FeedbackList) ? <span title="feedback" onClick={() => {dispatch(switchFeedback(false))}}><AddCircleOutlineIcon className={classes.feedbackColor} /></span> : null} */}
                            </div>
                            {/* {(switchProfile === "1") ? <button className={styles.profileButton + " ml-auto"} onClick={() => setSwitchProfile("2")}>My Creations</button> : <button className={styles.profileButton + " ml-auto"} onClick={() => setSwitchProfile("1")}>Profile</button>} */}
                        </div>
                        {(switchProfiler === "1") ? <div className={classes.spacing}>
                            <p className={customStyles.cardTitle}>{(Object.keys(auth).length > 0) ? auth.user.username : ""}</p>
                            <p className={customStyles.cardDesc}>Bengaluru, Karnataka | <img style={{ width: 22 }} src={IndianFlag} alt="india" /> India</p>
                        </div> : null}

                        {(switchProfiler === "1") ? <div>
                            <p className={customStyles.lastActive}>I am a Front End Developer. Can build simple, creative and fast-websites. My code is modular and easily understandable.</p>
                        </div> : null}

                        {(switchProfiler === "1") ?
                            <Row className={customStyles.spacing} style={{display:'none'}}>
                                <p className={customStyles.myCreationsTitle}>ALL CREATIONS</p>
                                <Col sm={12} className="d-flex">
                                    <div className={customStyles.grid} onClick={profileSwitcher} style={{ backgroundImage: "url(" + CompanyImage1 + ")" }}></div>
                                    <div className={customStyles.grid} style={{ backgroundImage: "url(" + CompanyImage2 + ")" }}></div>
                                    <div className={customStyles.grid} style={{ backgroundImage: "url(" + CompanyImage3 + ")" }}></div>
                                </Col>
                                <Col sm={12} className="d-flex mt-5">
                                    <div className={customStyles.grid} style={{ backgroundImage: "url(" + CompanyImage4 + ")" }}></div>
                                    <div className={customStyles.grid} style={{ backgroundImage: "url(" + CompanyImage5 + ")" }}></div>
                                    
                                </Col>
                                {/* <img src={PlusImage} alt="company5" title="More" className={customStyles.gridImage} /> */}
                                {/* </button> */}
                            </Row> : null}

                        {(switchProfiler === "2") ?
                            <div>
                                {(slotPage) ? <BookSlot date={calendarValue} bookSlotHandler={bookASlot} /> : <div>
                                    {(isCalendar) ?
                                        <List className={classes.root}>
                                            <ListItem
                                                button
                                                selected={selectedIndex === 0}
                                                onClick={(event) => handleListItemClick(event, 0)}
                                            >
                                                <ListItemText className={customStyles.listItem} primary={<Typography style={{width:'60%'}}>Hackathon</Typography>} secondary="Jan 9, 2014 8:30 AM" />
                                                <ListItemSecondaryAction style={{ right: 35 }}>
                                                    5 Minutes
                                        </ListItemSecondaryAction>
                                            </ListItem>
                                            <ListItem
                                                button
                                                selected={selectedIndex === 1}
                                                onClick={(event) => handleListItemClick(event, 1)}>
                                                <ListItemText className={customStyles.listItem} primary={<Typography style={{width:'60%'}}>Design Mockup Review</Typography>} secondary="Jan 7, 2014 9:30 PM" />
                                                <ListItemSecondaryAction style={{ right: 35 }}>
                                                    10 Minutes
                                        </ListItemSecondaryAction>
                                            </ListItem>
                                            <ListItem
                                                button
                                                selected={selectedIndex === 2}
                                                onClick={(event) => handleListItemClick(event, 2)}>
                                                <ListItemText className={customStyles.listItem} primary={<Typography style={{width:'60%'}}>Data Intensive application Bootcamp</Typography>} secondary="July 20, 2014 6:45 PM" />
                                                <ListItemSecondaryAction style={{ right: 35 }}>
                                                    20 Minutes
                                        </ListItemSecondaryAction>
                                            </ListItem>
                                            <div className="d-flex justify-content-center">
                                                <button className={customStyles.buttonDefault} onClick={() => { setCalendarFlag(false) }}>Book An Event</button>
                                            </div>
                                        </List> : <div className="mt-3">
                                            <button title="Back to Events" onClick={() => { setCalendarFlag(true) }} className={customStyles.backButton}><img alt="back" src={BackImage} className={customStyles.backIcon} /></button>
                                            <Calendar
                                                className={customStyles.calendar}
                                                onChange={onCalendarChange}
                                                value={calendarValue}
                                                minDate={new Date()}
                                            />
                                            <div className="d-flex justify-content-center">
                                                <button className={customStyles.buttonDefault} onClick={() => { setSlotPage(true) }}>Select</button>
                                            </div>
                                        </div>}
                                </div>}
                            </div> : null}

                        {(switchProfiler === "3") ?
                            <div>
                                <ProductBar />
                                <Visual />
                            </div> : null
                        }

                        {(switchProfiler === "4") ?
                            <div>
                                <Feedback feedbackHandler={() => { setSwitchProfile("1") }} />
                            </div> : null
                        }

                        {(switchProfiler === "5") ?
                            <div>
                                <GoalFlag showGraph={isGraph} />
                            </div> : null
                        }
                        <div style={{marginTop:'20px'}}>
                    <p id="earned" style={{fontSize:'25px',textDecoration: 'underline',fontFamily:'Segoe UI',float:'left',color:'indigo',cursor:'pointer'}}>Earned</p>
                    <p id="spent" style={{fontSize:'25px',textDecoration: 'underline',fontFamily:'Segoe UI',float:'right',color:'indigo',cursor:'pointer'}}>Spent</p></div>
                    </CardContent>
                    <div className="custom_spacing__W113n row" id="showearned" style={{display:'none'}} >
            
                <Row className={customStyles.spacing} >
                                
                                <Col sm={10} className="d-flex">
                                    <div className={customStyles.grid} onClick={profileSwitcher} style={{ backgroundImage: "url(" + CompanyImage1 + ")" }}></div>
                                    <div className={customStyles.grid} style={{ backgroundImage: "url(" + CompanyImage2 + ")" }}></div>
                                </Col>
                    
                                {/* <img src={PlusImage} alt="company5" title="More" className={customStyles.gridImage} /> */}
                                {/* </button> */}
                            </Row></div>

                            <div className="custom_spacing__W113n row" id="showspent" style={{display:'none'}} >
            
                <Row className={customStyles.spacing} >
                                
                                <Col sm={10} className="d-flex">
                                    <div className={customStyles.grid} onClick={profileSwitcher} style={{ backgroundImage: "url(" + CompanyImage4 + ")" }}></div>
                                    <div className={customStyles.grid} style={{ backgroundImage: "url(" + CompanyImage5 + ")" }}></div>
                                </Col>
                    
                                {/* <img src={PlusImage} alt="company5" title="More" className={customStyles.gridImage} /> */}
                                {/* </button> */}
                            </Row></div>
                </Card>
                
            </div>
            
        </Container>
    );
}

export default FeedLayout;
