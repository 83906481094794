export const singleUser = (token={}) =>({
    type:'SINGLE_USER',
    token
})

export const authUser = (token=false) =>({
    type:'AUTHORIZATION',
    token
})

export const switchProfile = (token=true) =>({
    type:'SWITCH_PROFILE',
    token
})


export const switchFeedback = (token=false) =>({
    type:'SWITCH_FEEDBACK',
    token
})