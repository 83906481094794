import firebase from 'firebase/app';
import "firebase/firestore"
var config = {
    apiKey: "AIzaSyADQeVeKRFah5UpZoHMSuB7zpuewF4weJA",
    authDomain: "willpay-62ca1.firebaseapp.com",
    projectId: "willpay-62ca1",
    storageBucket: "willpay-62ca1.appspot.com",
    messagingSenderId: "569740205886",
    appId: "1:569740205886:web:bd66d221298ceb5730966f",
    measurementId: "G-JXC8RP0V9N"
};

firebase.initializeApp(config);
export const firestore = firebase.firestore()
export default firebase;