import React from 'react';
import { Card, Container } from 'react-bootstrap';
import SampleImage from '../images/light-bulb.jpg';
import { Link } from 'react-router-dom';
import ProfileImage from '../images/profile.jpg';
import styles from './feed/feed.module.css';
import Tab from '../components/tab';
import Menu from '../components/menu';
import customStyles from './feed/feed.module.css';
import Menu2 from '../components/menu2';
import { useDispatch } from 'react-redux';
import ProfileImage1 from '../images/profile1.jpg';
import ProfileImage2 from '../images/profile2.png';
import { switchProfile } from '../redux-store/action';
import LinearProgress from '@material-ui/core/LinearProgress';
import Plus from '../images/plus.png';
import './price.css';
import CompanyImage1 from '../images/company1.png';
import CompanyImage2 from '../images/company2.png';
import CompanyImage3 from '../images/company3.png';
import CompanyImage4 from '../images/company4.png';
import CompanyImage5 from '../images/slack.jpg';
import {  Row, Col } from 'react-bootstrap';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import $ from 'jquery';
import Popper from 'popper.js';
import Dropdown from "react-bootstrap/Dropdown";
import { IconButton } from '@material-ui/core';
import { Equalizer, PermIdentity } from '@material-ui/icons';
import MenuBar from '../images/menu.png';
import { Modal } from 'react-bootstrap';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import createStyles from './create.module.css';
import Slider from '@material-ui/core/Slider';
import EmailCopy from '../images/copy.png';
import ViewIcon from '../../images/view.png';
import PrivateIcon from '../../images/restriction.png';
import CreatableSelect from 'react-select/creatable';
import SelectOptions from './data';
import { useHistory } from 'react-router-dom';
import { CreateData } from '../interface';
import Create from './create/index';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
    root: {
        minHeight: 480,
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: '1.5rem'
    },
    rounded: {
        borderRadius: 30,
        width: 64,
        marginRight: 'auto'
    },
    profile: {
        borderRadius: 30,
        width: 48,
        marginRight: 'auto'
    },
    spacing: {
        paddingTop: '1.25rem'
    },
    templateSwitch: {
        margin: 0
    }
});

function Earned(props) {
    const dispatch = useDispatch();


    // const [willPay, SetWillPay] = React.useState(false);
    // const [willNot, SetWillNot] = React.useState(false);
     const [submitted, SetSubmit] = React.useState(false);
     const [value, setValue] = React.useState([20, 37]);
     const [selectedValue, setSelectedValue] = React.useState('Yes');
     const [show, setShow] = React.useState(false);
     const handleClose = () => {
         setShow(false);
         SetSubmit(true);
     };
     const handleShow = () => setShow(true);
     const handleChange = (event, newValue) => {
         setValue(newValue);
     };
    const handleCheckChange = (event) => {
         setSelectedValue(event.target.value);
     };
     const classes = useStyles();
   // const showWillPayOptions = () => {
    //     SetWillPay(true);
    //     SetWillNot(false);
    // };

    // const showWillNotOptions = () => {
    //     SetWillNot(true);
    //     SetWillPay(false);
    // }

    const [chartVisible, SetChart] = React.useState(true);
    React.useEffect(() => {
        const coupons=document.getElementById('coupons') as HTMLElement;

        const hovercard=document.getElementById('hovercard') as HTMLElement;
        const hovercarddisplay=document.getElementById('hovercarddisplay') as HTMLElement;
        hovercard.addEventListener('mouseenter',function(){
            hovercarddisplay.style.visibility='visible';
            hovercarddisplay.style.display='block';
        })
        hovercard.addEventListener('mouseout',function(){
            hovercarddisplay.style.visibility='hidden';
            hovercarddisplay.style.transition='visibility 5s'
        })
    
    const showcoupons=document.getElementById('showcoupons') as HTMLElement;
    const closecoupons=document.getElementById('closecoupons') as HTMLElement;
    if(closecoupons){
        closecoupons.addEventListener('click',function(event){
            showcoupons.style.display='none';
        })}
    
    const couponstwo=document.getElementById('couponstwo') as HTMLElement;
    const showcouponstwo=document.getElementById('showcouponstwo') as HTMLElement;
    function couponsTwo(event){
        couponstwo.style.display='block';
        showcoupons.style.display='none';
    }
    if(showcouponstwo){
        showcouponstwo.addEventListener('click',couponsTwo)}
    
    const closecouponstwo=document.getElementById('closecouponstwo') as HTMLElement;
    if(closecouponstwo){
        closecouponstwo.addEventListener('click',function(event){
            couponstwo.style.display='none';
        })}

    
        coupons.addEventListener('click',function(){
            showcoupons.style.display='block';
            couponstwo.style.display='none';




    }) }, []);
    const profileSwitcher = () => {
        dispatch(switchProfile(false));
    }

    return (
        <div>
            <Menu2 />


            
            <Container className="mt-3" >
            <Card style={{width:'300px',marginBottom:'-100px',marginLeft:'-300px',padding:'10px',float:'left',marginTop:'200px'}} className={classes.root+"custom_spacing__W113n row"+customStyles.profileCard}>
            <Row className={customStyles.spacing}>
                                <p style={{marginLeft:'100px',fontFamily:'ManMedium'}} className={customStyles.myCreationsTitle}>ALL CREATIONS</p>
                                <Col sm={12} className="d-flex">
                                    <div className={customStyles.grid} onClick={profileSwitcher} style={{ backgroundImage: "url(" + CompanyImage1 + ")" }}></div>
                                    <div className={customStyles.grid} style={{ backgroundImage: "url(" + CompanyImage2 + ")" }}></div>
                                    <div className={customStyles.grid} style={{ backgroundImage: "url(" + CompanyImage3 + ")" }}></div>
                                </Col>
                                <Col sm={12} className="d-flex mt-3">
                                    <div className={customStyles.grid} style={{ backgroundImage: "url(" + CompanyImage4 + ")" }}></div>
                                    <div className={customStyles.grid} style={{ backgroundImage: "url(" + CompanyImage5 + ")" }}></div>
                                    <Link to="/create"><div className={customStyles.grid} style={{ backgroundImage: "url(" + Plus + ")" }}></div></Link>
                                    
                                </Col>
                                {/* <img src={PlusImage} alt="company5" title="More" className={customStyles.gridImage} /> */}
                                {/* </button> */}
                            </Row></Card>
            
            <Card style={{width:'fit-content',padding:'10px'}} className={"mx-auto mt-5 text-center "+styles.profileCard+"custom_spacing__W113n row"}>
            <Row className={customStyles.spacing}>
                                <Col sm={12} className="d-flex">
                                <img style={{width:'64px',height:'64px'}} className='avatar' src={CompanyImage1}/>
                                <img style={{width:'64px',height:'64px'}} className='avatar' src={CompanyImage2}/>
                                <img style={{width:'64px',height:'64px'}} className='gavatar' src={CompanyImage3}/>
                                <img style={{width:'64px',height:'64px'}} className='gavatar' src={CompanyImage4}/>
                                <img style={{width:'64px',height:'64px'}} className='gavatar' src={CompanyImage5}/>
                                <Link to="/create" ><img style={{width:'64px',height:'64px'}}  src={Plus}/></Link>
                                </Col>

                            </Row>
            

                    </Card>
            <p style={{marginLeft:'320px',fontFamily:'ManMedium',marginTop:'50px'}}>19 July,2022</p>
                <div className="mx-auto">

                <div id="showcoupons" style={{boxShadow:'0 4px 8px 0 rgba(0,0,0,0.2)',transition:'0.3s',width:'30%',borderRadius:'5px',float:'right',padding:'10px',marginTop:'75px',display:'none',marginLeft:'-200px',marginRight:'-330px'}}>
                        <p style={{textAlign:'center',fontWeight: 800,fontSize: '1vw',textTransform: 'uppercase',fontFamily:'ManMedium'}}>COMMENTS<svg id="closecoupons" style={{width:'12px',height:'12px',float:'right',cursor:'pointer'}} version="1.1"
     xmlns="http://www.w3.org/2000/svg">
    <line x1="1" y1="11" 
          x2="11" y2="1" 
          stroke="black" 
          stroke-width="2"/>
    <line x1="1" y1="1" 
          x2="11" y2="11" 
          stroke="black" 
          stroke-width="2"/>
</svg></p>
                        <img src='https://pbs.twimg.com/profile_images/1503595584489668610/mHNfif7w_400x400.jpg' className={styles.feedProfile} /><div className="ml-auto float-right" style={{cursor:'pointer'}}><p className="card-price" style={{fontFamily:'ManMedium'}}>30$</p></div><p style={{textAlign:'left',fontSize:'15px',fontFamily:'ManMedium'}}>This idea is actually insane and helped me a lot.Keep going team</p>
                        <div style={{ height: 4,width:'auto',marginTop:'12px', backgroundColor: '#3F51B5',marginBottom:'10px'  }}></div>
                        <img src={ProfileImage1} className={styles.feedProfile} /><div className="ml-auto float-right" style={{cursor:'pointer'}}><p className="card-price" style={{fontFamily:'ManMedium'}}>30$</p></div><p style={{textAlign:'left',fontSize:'15px',fontFamily:'ManMedium'}}>Clean and simple to understand.Great work👏</p>
                        <div style={{ height: 4,width:'auto',marginTop:'14px', backgroundColor: '#3F51B5',marginBottom:'10px' }}></div>
                        <img src={ProfileImage2} className={styles.feedProfile} /><div className="ml-auto float-right" style={{cursor:'pointer'}}><p className="card-price" style={{fontFamily:'ManMedium'}}>30$</p></div><p style={{textAlign:'left',fontSize:'15px',fontFamily:'ManMedium'}}>Looking forward on how this project goes</p>
                        <div style={{ height: 4,width:'auto',marginTop:'12px', backgroundColor: '#3F51B5',marginBottom:'10px'}}></div>
                        
                        </div>
                        <div id="couponstwo" style={{boxShadow:'0 4px 8px 0 rgba(0,0,0,0.2)',transition:'0.3s',width:'30%',borderRadius:'5px',float:'right',padding:'10px',display:'none',textAlign:'center',fontWeight: 800,fontSize: '1vw',textTransform: 'uppercase',marginTop:'70px',marginLeft:'-200px',marginRight:'-330px'}}>
                        <p >COUPONS AND DEALS<svg id="closecouponstwo" style={{width:'12px',height:'12px',float:'right',cursor:'pointer'}} version="1.1"
     xmlns="http://www.w3.org/2000/svg">
    <line x1="1" y1="11" 
          x2="11" y2="1" 
          stroke="black" 
          stroke-width="2"/>
    <line x1="1" y1="1" 
          x2="11" y2="11" 
          stroke="black" 
          stroke-width="2"/>
</svg></p>
                        <img src="https://m-cdn.phonearena.com/images/article/104966-wide-two_350/Bargain-hunters-use-these-apps-to-find-deals-coupons-and-get-cashbacks.jpg?1532523286" alt="Avatar" style={{width:'30%'}}></img>
                        <div className="ml-auto float-right" style={{cursor:'pointer'}}><p className="card-price" style={{fontFamily:'ManMedium'}}>30$</p></div><p style={{textAlign:'left',fontSize:'15px',fontFamily:'ManMedium'}}>SignUp and get 2 months extra with paytm</p>
                        <div style={{ height: 4,width:'30%',marginTop:'14px', backgroundColor: '#3F51B5',marginBottom:'10px' }}></div>
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRi4KLFlP0T3f6ZO0tLc1gKmCnAzBcs9Bfiyg&usqp=CAU" style={{width:'30%'}} alt="Avatar" ></img>
                            <div className="ml-auto float-right" style={{cursor:'pointer'}}><p className="card-price" style={{fontFamily:'ManMedium'}}>30$</p></div><p style={{textAlign:'left',fontSize:'15px',fontFamily:'ManMedium'}}>Redeem and get spotify for 1 month ad free music</p>
                        <div style={{ height: 4,width:'30%',marginTop:'14px', backgroundColor: '#3F51B5',marginBottom:'10px' }}></div>
                                <img src="https://cbshop.in/wp-content/uploads/2021/10/Norton-360-offers.jpg" style={{width:'30%'}} alt="Avatar" ></img>
                                <div className="ml-auto float-right" style={{cursor:'pointer'}}><p className="card-price" style={{fontFamily:'ManMedium'}}>30$</p></div><p style={{textAlign:'left',fontSize:'15px',fontFamily:'ManMedium'}}>Fill this survey and get rewarded with paytm</p>
                        <div style={{ height: 4,width:'30%',marginTop:'14px', backgroundColor: '#3F51B5',marginBottom:'10px' }}></div>
                                </div>
                                
           
                    <Card className = {"mx-auto mt-3 mb-3 "+styles.profileCard} >
                        <Card.Header className={"d-flex align-items-center " + styles.feedCard+"grid grid-cols-1 gap-4"}><img src={CompanyImage1} alt="" className={styles.feedProfile} /><span className="ml-2" style={{fontFamily:'ManMedium'}}>DMatiz</span><div></div>

<div style={{marginLeft:'300px',backgroundColor:'white'}}className="btn-group">
  <button  type="button" className="btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16"> <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/> </svg>
  </button>
  <div className="dropdown-menu">
    <a className="dropdown-item" href="#" style={{fontFamily:'ManMedium'}}>Edit</a>
    <a className="dropdown-item" href="#" style={{fontFamily:'ManMedium'}} >Delete</a>
    <a className="dropdown-item" href="#" style={{fontFamily:'ManMedium'}} >Active</a>
    <a className="dropdown-item" href="#" style={{fontFamily:'ManMedium'}} >Share</a>
    <a className="dropdown-item" href="#" style={{fontFamily:'ManMedium'}} onClick={handleShow}>Embed</a>
  </div>
</div>
                        </Card.Header>

                        <div id="showcouponstwo" className="ml-auto float-right" style={{cursor:'pointer',marginRight:'-0px',marginBottom:'-54px'}}><p className="price" style={{fontFamily:'ManMedium'}}>30$</p></div>
                        
                        <Card.Img variant="top" alt="" src={SampleImage} />
                        
                        <Card.Body>
                            <Card.Title className={styles.feedCard}>rock-o-band</Card.Title>
                            <Card.Text className={styles.feedDesc}>
                                I am a very simple card. I am good at containing small bits of information. I am convenient because I require little markup to use effectively.
                        </Card.Text>
                            <div>
                                <span className={styles.tags}>IT</span>
                                <span className={styles.tags}>SaaS</span>
                                <span className={styles.tags}>Movies</span>
                            </div>
                        </Card.Body>
                        <div>
                            <div className="d-flex">
                                <div style={{ width: '60%' }}>
                                    <p className={styles.customDisable + " pl-1"}>60%</p>
                                    <div style={{ height: 4, backgroundColor: '#747474' }}></div>
                                </div>
                                <div id='coupons' style={{ width: '40%',cursor:'pointer' }} className="text-right">
                                    <p className={styles.willPayColor + " pr-1"}>40%</p>
                                    <div style={{ height: 4, backgroundColor: '#54A538' }}></div>
                                </div>
                            </div>
                        </div>
                    </Card>
                    <div id="couponstwo" style={{boxShadow:'0 4px 8px 0 rgba(0,0,0,0.2)',transition:'0.3s',width:'30%',borderRadius:'5px',float:'right',padding:'10px',display:'none',textAlign:'center',fontSize: '1vw',textTransform: 'uppercase',marginTop:'70px'}}>
                        <p >COUPONS AND DEALS<svg id="closecouponstwo" style={{width:'12px',height:'12px',float:'right',cursor:'pointer'}} version="1.1"
     xmlns="http://www.w3.org/2000/svg">
    <line x1="1" y1="11" 
          x2="11" y2="1" 
          stroke="black" 
          stroke-width="2"/>
    <line x1="1" y1="1" 
          x2="11" y2="11" 
          stroke="black" 
          stroke-width="2"/>
</svg></p>
                        <img src="https://m-cdn.phonearena.com/images/article/104966-wide-two_350/Bargain-hunters-use-these-apps-to-find-deals-coupons-and-get-cashbacks.jpg?1532523286" alt="Avatar" style={{width:'100%'}}></img>
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRi4KLFlP0T3f6ZO0tLc1gKmCnAzBcs9Bfiyg&usqp=CAU" style={{width:'100%'}} alt="Avatar" ></img>
                                <img src="https://cbshop.in/wp-content/uploads/2021/10/Norton-360-offers.jpg" style={{width:'100%'}} alt="Avatar" ></img></div>
                    <p style={{marginLeft:'320px',fontFamily:'ManMedium',marginTop:'50px'}}>19 July,2022</p>
                    <Card className = {"mx-auto mt-3 mb-5 "+styles.profileCard}>
                        <Card.Header className={"d-flex align-items-center " + styles.feedCard}><img src={CompanyImage2} alt="" className={styles.feedProfile} /><span className="ml-2">DMatiz</span><div></div>
                        <div style={{marginLeft:'310px',backgroundColor:'white'}}className="btn-group">
  <button  type="button" className="btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16"> <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/> </svg>
  </button>
  <div className="dropdown-menu">
    <a className="dropdown-item" href="#" style={{fontFamily:'ManMedium'}}>Edit</a>
    <a className="dropdown-item" href="#" style={{fontFamily:'ManMedium'}} >Delete</a>
    <a className="dropdown-item" href="#" style={{fontFamily:'ManMedium'}} >Active</a>
    <a className="dropdown-item" href="#" style={{fontFamily:'ManMedium'}} >Share</a>
    <a className="dropdown-item" href="#" style={{fontFamily:'ManMedium'}} >Embed</a>
  </div>
</div>
                        
                        </Card.Header>
                        <div id="showcouponstwo" className="ml-auto float-right" style={{cursor:'pointer',marginRight:'-0px',marginBottom:'-54px'}}><p className="price" style={{fontFamily:'ManMedium'}}>30$</p></div>
                        <Card.Img variant="top" alt="" src={SampleImage} />
                        <Card.Body>
                            <Card.Title className={styles.feedCard}>rock-o-band</Card.Title>
                            <Card.Text className={styles.feedDesc}>
                                I am a very simple card. I am good at containing small bits of information. I am convenient because I require little markup to use effectively.
                        </Card.Text>
                            <div>
                                <span className={styles.tags}>IT</span>
                                <span className={styles.tags}>SaaS</span>
                                <span className={styles.tags}>Movies</span>
                            </div>
                        </Card.Body>
                        
                        <div style={{display:'flex',margin:'auto',width:'auto',marginTop:'10px',position:'relative'}}>
                            <img id="hovercard" style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center',marginLeft:'-220px'}} onClick={profileSwitcher} src='https://pbs.twimg.com/profile_images/1503595584489668610/mHNfif7w_400x400.jpg' className={styles.feedProfile} />&nbsp;
                            <img style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center'}} onClick={profileSwitcher} src={ProfileImage1} className="avatar" />&nbsp;
                            <img style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center'}} onClick={profileSwitcher} src={ProfileImage2} className="avatar" />&nbsp;
                            <img style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center'}} onClick={profileSwitcher} src={ProfileImage1} className="gavatar" />&nbsp;
                            <img style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center'}} onClick={profileSwitcher} src={ProfileImage1} className="gavatar" />&nbsp;
                            <img style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center'}} onClick={profileSwitcher} src={ProfileImage1} className="gavatar" />&nbsp;
                            
                            <svg style={{cursor:'pointer',marginTop:'10px'}} id="showcomments" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18" ><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
                        </div>
                        <div>
                            <p className={"text-center " + styles.loadingEngage}>Loading Engagements</p>
                            <LinearProgress />
                        </div>
                    </Card>
                    <Card id="hovercarddisplay" style={{visibility:'hidden',display:'none',position:'absolute',marginTop:'-10px'}} className = {styles.profileCard}>
                        <Card.Header className={"d-flex align-items-center " + styles.feedCard+"grid grid-cols-1 gap-4"}><img src={ProfileImage} alt="" className={styles.feedProfile} /><span className="ml-2" style={{fontFamily:'ManMedium'}}>DMatiz</span><div></div>
                       
                        </Card.Header>
                        
                        
                        <Card.Body>
                            <Card.Title className={styles.feedCard}>I'm a Software Programmer from Canada</Card.Title>

                        </Card.Body>
                        
                    </Card>
                </div>
                <Modal show={show} onHide={handleClose} centered>
                <Modal.Body className="text-center p-4">
                    <img src={EmailCopy} className={styles.feedEmailLogo}/><p className={styles.feedModalHeader}>Copy the embed script to use</p>
                    <span className={styles.feedModalEmailCopy}>script</span>
                    <div className="text-center mt-3">
                        <button className={styles.TabActive} onClick={handleClose}>Close</button>
                    </div>
                </Modal.Body>
            </Modal>
            </Container>
        </div>
    );




}



export default Earned;



