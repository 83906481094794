import SuccessImage from '../../images/check-mark.png';
import styles from './feedbackThankYou.module.css';
import EmailCopy from '../../images/copy.png';

const FeedBackThankYou: React.FC<{handleClose:() => void}> = (props) => {
    const handleClose = () => {
        props.handleClose();
    }
    return (
        <div>
            <p className={styles.feedModalHeader}>Thank you for providing your feedback!</p>
            <p className={styles.feedModalDesc}>Copy the creators email below!</p>
            <span className={styles.feedModalEmailCopy}><img src={EmailCopy} className={styles.feedEmailLogo} /> gkadkol@bookmane.in</span>
            <div className="text-center mt-3">
                <button className={styles.TabActive} onClick={handleClose}>FEED</button>
            </div>
        </div>
    )
}

export default FeedBackThankYou;