import React from 'react';
import { Col, Row, Card} from 'react-bootstrap';
import styles from './home/home.module.css';
import LinkPreview from '@ashwamegh/react-link-preview';
import '@ashwamegh/react-link-preview/dist/index.css';
import Loader from '../images/loader.gif';
import Star from '../images/stars.gif';
import TweetEmbed from 'react-tweet-embed';
import TwitterLogo from '../images/twitter.png';

function CustomComponent ({ loading, preview }) {
	return loading 
	? (<div className="text-center"><img src={Loader} alt="" style={{width:128}}/></div>)
	: (
        <div className={styles.thankYouLinkWrapper}>
        <div className={styles.thankYouDesc}>
            <div className={styles.thankYouTitle}>
                <span className={styles.thankYouTitleDot}>{preview.domain}</span>
            </div>
            <div>
                <div className={styles.thankYouHeader}>{preview.title}</div>
                <div className={styles.thankYouBody}>
                    {preview.description}
                </div>
            </div>
        </div>
        <div className={styles.thankYouImage}>
            <img src={preview.img} alt="Thankyou" />
        </div>
    </div>
	)
};

function ThankYouScreen(props) {
    const rand = (min,max) =>{
        var offset = min;
        var range = (max-min) + 1;
        var randomnumber = Math.floor(Math.random()*range) + offset;
        return randomnumber;
    }
    const [thankYouLink, SetThankYou] = React.useState("");
    const [tweetId, SetTweet] = React.useState("");
    const [isFlag, SetFlagged] = React.useState(false);
    React.useEffect(()=>{
        fetch('https://spreadsheets.google.com/feeds/cells/1G5qvklEpFnMVIU5-QsOLHgK_oAQN8EC3wI8sOOt4cSU/1/public/full?alt=json').then(res=>res.json())
        .then((result)=>{
            var randomnumber = rand(0,result.feed.entry.length-1);
            SetThankYou(result.feed.entry[randomnumber].content.$t);
        })
        
        fetch('https://spreadsheets.google.com/feeds/cells/1G5qvklEpFnMVIU5-QsOLHgK_oAQN8EC3wI8sOOt4cSU/2/public/full?alt=json').then(res=>res.json())
        .then((result)=>{
            SetFlagged(true);
            var randomnumber = rand(0,result.feed.entry.length-1);
            console.log(result.feed.entry[randomnumber].content.$t);
            SetTweet(result.feed.entry[randomnumber].content.$t);
        })
    },[]);

    const setHome = () =>{
        props.setPreviousPage();
    }
    return (
        <Row className="no-gutters">
            <Col sm={12}>
                <div className="text-center mt-4">
                    <span className={styles.goBack} onClick={setHome}>Home</span>
                </div>
                <div className="text-center mt-2">
                    <p style={{fontSize:'18px'}}>Thank you, lets build a fantastic community of microsaas creators.</p>
                </div>
                <Card body className={styles.cardThankYou}>
                    <p className="text-center"><img src={Star} alt="" style={{width:50}} />Awesome, this is so cool.<img src={Star} alt="" style={{width:50}} /></p><p className="text-center">Like the support, we will do our best to make this tool work for you.</p><p className={"text-center "+styles.thankYouSubtitle}>If you are working on any side project or app or utility or tool, <a href="https://twitter.com/intent/tweet?text=@seebiscut"> <img src={TwitterLogo} style={{width:24}} alt="" /></a> us.</p>
                    <LinkPreview url={thankYouLink} render={CustomComponent}/>
                    <div style={{width:420}} className="mx-auto">
                    <TweetEmbed id={tweetId}/>
                    </div>
                </Card>
                
            </Col>
        </Row>
    );
}

export default ThankYouScreen;
