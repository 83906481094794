import React, { useEffect, useState } from 'react';
import styles from './menu.module.css';
import { Navbar, Nav } from 'react-bootstrap';
import menuLogo from '../../images/logo.png';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import { useDispatch } from 'react-redux';
import { switchProfile } from '../../redux-store/action';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import ProfileImage from '../../images/profile.jpg';
import styless from '../../screens/feed/feed.module.css';
import './menu.module.css';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));


function SimpleMenu() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [auth,setAuth] = useState<{user ?: any}>({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const authSet = () => {
    localStorage.clear();
    history.push('/');
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const setFeed = () => {
    dispatch(switchProfile(true));
  }

  useEffect(()=>{
    if(localStorage.getItem('profile')){
      setAuth(JSON.parse(localStorage.getItem('profile')));
    }else{
      history.push('/demo');
    }
  },[]);
  const [menu,setMenu]=useState()
  
  return (
    <Navbar  className="bg-white">
       <Link to="/profile" ><Avatar
            onClick={handleClick}
            alt="Remy Sharp"
            //src={(Object.keys(auth).length>0)?auth.user.profile_image:""}
            src={ProfileImage}
            className={classes.large}
          /></Link>
      <Link id="navbarbrand" to="/feed" style={{marginLeft:'650px'}} onClick={setFeed} className="navbar-brand"><img src={menuLogo} alt="def" style={{ width: 90 }} /></Link>
      <Link to="/logout" style={{marginLeft:'500px'}}><button className={styless.indigo + " mr-auto"} >Logout</button></Link>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className={"ml-auto "+styles.avatarProfile}>
          {/* <Link onClick={authSet} className={styles.menu+ " nav-link"} to="/">
            Sign Out
          </Link> */}
          
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>{(Object.keys(auth).length>0)?auth.user.username:""}</MenuItem>
            <MenuItem><Link  to="/profile">Profile</Link></MenuItem>
            <MenuItem><Link onClick={authSet} to="/demo">Logout</Link></MenuItem>
          </Menu>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default SimpleMenu;
