import React from 'react';
import { Card, Container } from 'react-bootstrap';
import SampleImage from '../images/light-bulb.jpg';
import ProfileImage from '../images/profile.jpg';
import styles from './feed/feed.module.css';
import Tab from '../components/tab';
import Menu from '../components/menu';
import customStyles from './feed/feed.module.css';
import './price.css';
import { Link } from 'react-router-dom';
import Menu2 from '../components/menu2';
import ProfileImage1 from '../images/profile1.jpg';
import ProfileImage2 from '../images/profile2.png';
import { useDispatch } from 'react-redux';
import { switchProfile } from '../redux-store/action';
function Spent() {
    const dispatch = useDispatch();
    // const [willPay, SetWillPay] = React.useState(false);
    // const [willNot, SetWillNot] = React.useState(false);
    // const [submitted, SetSubmit] = React.useState(false);
    // const [value, setValue] = React.useState([20, 37]);
    // const [selectedValue, setSelectedValue] = React.useState('Yes');
    // const [show, setShow] = React.useState(false);
    // const handleClose = () => {
    //     setShow(false);
    //     SetSubmit(true);
    //     SetWillNot(false);
    //     SetWillPay(false);
    // };
    // const handleShow = () => setShow(true);
    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };

    // const handleCheckChange = (event) => {
    //     setSelectedValue(event.target.value);
    // };

    // const showWillPayOptions = () => {
    //     SetWillPay(true);
    //     SetWillNot(false);
    // };

    // const showWillNotOptions = () => {
    //     SetWillNot(true);
    //     SetWillPay(false);
    // }

//    <div className="d-flex card-body" style={{width:'600px',textAlign:'center',justifyContent:'center',alignItems:'center',margin:'auto'}}>
//    <Link to="/earned" ><button className={styles.indigo + " mr-auto"} >Earned</button></Link>
//                    <div style={{ height: 4,width:'500px',marginTop:'12px', backgroundColor: '#3F51B5' }}></div>
//                    <Link to="/profile" ><img alt="profile" src={ProfileImage} style={{width:'64px',height:'64px'}} /></Link>
//                    <div style={{ height: 4,width:'500px',marginTop:'12px', backgroundColor: '#3F51B5' }}></div>
//                    <Link to="/spent" ><button className={styles.indigo} >Spent</button></Link></div>
React.useEffect(() => {
const coupons=document.getElementById('coupons') as HTMLElement;
const showcoupons=document.getElementById('showcoupons') as HTMLElement;
coupons.addEventListener('click',function(){
    showcoupons.style.display='block';
}) }, []);

const profileSwitcher = () => {
    dispatch(switchProfile(false));
}
    return (
        <div>
            <Menu2 />

            <Container className="mt-3">
                <p style={{marginLeft:'320px',fontFamily:'ManMedium',marginTop:'50px'}}>19 July,2022</p>
                <div className="mx-auto">
                <div id="showcoupons" style={{boxShadow:'0 4px 8px 0 rgba(0,0,0,0.2)',transition:'0.3s',width:'30%',borderRadius:'5px',float:'right',padding:'10px',marginTop:'75px',display:'none',marginLeft:'-200px',marginRight:'-330px'}}>
                        <p style={{textAlign:'center',fontWeight: 800,fontSize: '1vw',textTransform: 'uppercase',fontFamily:'ManMedium'}}>COMMENTS<svg id="closecoupons" style={{width:'12px',height:'12px',float:'right',cursor:'pointer'}} version="1.1"
     xmlns="http://www.w3.org/2000/svg">
    <line x1="1" y1="11" 
          x2="11" y2="1" 
          stroke="black" 
          stroke-width="2"/>
    <line x1="1" y1="1" 
          x2="11" y2="11" 
          stroke="black" 
          stroke-width="2"/>
</svg></p>
                        <img src='https://pbs.twimg.com/profile_images/1503595584489668610/mHNfif7w_400x400.jpg' className={styles.feedProfile} /><div className="ml-auto float-right" style={{cursor:'pointer'}}></div><p style={{textAlign:'left',fontSize:'15px',fontFamily:'ManMedium'}}>This idea is actually insane and helped me a lot.Keep going team</p>
                        <div style={{ height: 4,width:'auto',marginTop:'12px', backgroundColor: '#3F51B5',marginBottom:'10px'  }}></div>
                        <img src={ProfileImage1} className={styles.feedProfile} /><div className="ml-auto float-right" style={{cursor:'pointer'}}></div><p style={{textAlign:'left',fontSize:'15px',fontFamily:'ManMedium'}}>Clean and simple to understand.Great work👏</p>
                        <div style={{ height: 4,width:'auto',marginTop:'14px', backgroundColor: '#3F51B5',marginBottom:'10px' }}></div>
                        <img src={ProfileImage2} className={styles.feedProfile} /><div className="ml-auto float-right" style={{cursor:'pointer'}}></div><p style={{textAlign:'left',fontSize:'15px',fontFamily:'ManMedium'}}>Looking forward on how this project goes</p>
                        <div style={{ height: 4,width:'auto',marginTop:'12px', backgroundColor: '#3F51B5',marginBottom:'10px'}}></div>
                        
                        </div>
                    <Card className = {"mx-auto mt-3 mb-3 "+styles.profileCard}>
                        <Card.Header className={"d-flex align-items-center " + styles.feedCard}><img src={ProfileImage} alt="" className={styles.feedProfile} /><span className="ml-2">DMatiz</span><div></div>
                        <div style={{marginLeft:'310px',backgroundColor:'white'}}className="btn-group">
  <button  type="button" className="btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16"> <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/> </svg>
  </button>
  <div className="dropdown-menu">
    <a className="dropdown-item" href="#" style={{fontFamily:'ManMedium'}}>Share</a>

  </div>
</div>
                        
                        </Card.Header>
                        <div  className="ml-auto float-right" style={{cursor:'pointer',marginRight:'-0px',marginBottom:'-54px'}}><p className="price" style={{fontFamily:'ManMedium'}}>30$</p></div>
                        <Card.Img variant="top" alt="" src={SampleImage} />
                        
                        <Card.Body>
                            <Card.Title className={styles.feedCard}>rock-o-band</Card.Title>
                            <Card.Text className={styles.feedDesc}>
                                I am a very simple card. I am good at containing small bits of information. I am convenient because I require little markup to use effectively.
                        </Card.Text>
                            <div>
                                <span className={styles.tags}>IT</span>
                                <span className={styles.tags}>SaaS</span>
                                <span className={styles.tags}>Movies</span>
                            </div>
                        </Card.Body>
                        <div style={{display:'flex',margin:'auto',width:'auto',marginTop:'10px',position:'relative'}}>
                            <img id="hovercard" style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center',marginLeft:'-220px'}} onClick={profileSwitcher} src='https://pbs.twimg.com/profile_images/1503595584489668610/mHNfif7w_400x400.jpg' className={styles.feedProfile} />&nbsp;
                            <img style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center'}} onClick={profileSwitcher} src={ProfileImage1} className="avatar" />&nbsp;
                            <img style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center'}} onClick={profileSwitcher} src={ProfileImage2} className="avatar" />&nbsp;
                            <img style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center'}} onClick={profileSwitcher} src={ProfileImage1} className="gavatar" />&nbsp;
                            <img style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center'}} onClick={profileSwitcher} src={ProfileImage1} className="gavatar" />&nbsp;
                            <img style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center'}} onClick={profileSwitcher} src={ProfileImage1} className="gavatar" />&nbsp;
                            
                            <svg style={{cursor:'pointer',marginTop:'10px'}} id="showcomments" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18" ><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
                        </div>
                        <div>
                            <div className="d-flex">
                                <div id='coupons' style={{ width: '60%' }}>
                                    <p className={styles.customDisable + " pl-1"}>60%</p>
                                    <div style={{ height: 4, backgroundColor: '#747474' }}></div>
                                </div>
                                <div style={{ width: '40%' }} className="text-right">
                                    <p className={styles.willPayColor + " pr-1"}>40%</p>
                                    <div style={{ height: 4, backgroundColor: '#54A538' }}></div>
                                </div>
                            </div>
                        </div>
                    </Card>
                    
                    <p style={{marginLeft:'320px',fontFamily:'ManMedium'}}>16 July,2022</p>
                    <Card className = {"mx-auto mt-3 mb-5 "+styles.profileCard}>
                        <Card.Header className={"d-flex align-items-center " + styles.feedCard}><img src={ProfileImage} alt="" className={styles.feedProfile} /><span className="ml-2">DMatiz</span><div></div>
                        <div style={{marginLeft:'310px',backgroundColor:'white'}}className="btn-group">
  <button  type="button" className="btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16"> <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/> </svg>
  </button>
  <div className="dropdown-menu">
    <a className="dropdown-item" href="#" style={{fontFamily:'ManMedium'}}>Share</a>

  </div>
</div>
                        
                        </Card.Header>
                        <div  className="ml-auto float-right" style={{cursor:'pointer',marginRight:'-0px',marginBottom:'-54px'}}><p className="price" style={{fontFamily:'ManMedium'}}>30$</p></div>
                        <Card.Img variant="top" alt="" src={SampleImage} />
                        <Card.Body>
                            <Card.Title className={styles.feedCard}>rock-o-band</Card.Title>
                            <Card.Text className={styles.feedDesc}>
                                I am a very simple card. I am good at containing small bits of information. I am convenient because I require little markup to use effectively.
                        </Card.Text>
                            <div>
                                <span className={styles.tags}>IT</span>
                                <span className={styles.tags}>SaaS</span>
                                <span className={styles.tags}>Movies</span>
                            </div>
                        </Card.Body>
                        <div style={{display:'flex',margin:'auto',width:'auto',marginTop:'10px',position:'relative'}}>
                            <img id="hovercard" style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center',marginLeft:'-220px'}} onClick={profileSwitcher} src='https://pbs.twimg.com/profile_images/1503595584489668610/mHNfif7w_400x400.jpg' className={styles.feedProfile} />&nbsp;
                            <img style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center'}} onClick={profileSwitcher} src={ProfileImage1} className="avatar" />&nbsp;
                            <img style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center'}} onClick={profileSwitcher} src={ProfileImage2} className="avatar" />&nbsp;
                            <img style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center'}} onClick={profileSwitcher} src={ProfileImage1} className="gavatar" />&nbsp;
                            <img style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center'}} onClick={profileSwitcher} src={ProfileImage1} className="gavatar" />&nbsp;
                            <img style={{width:'36px',height:'36px',cursor:'pointer',borderRadius:'50%',alignItems:'center'}} onClick={profileSwitcher} src={ProfileImage1} className="gavatar" />&nbsp;
                            
                            <svg style={{cursor:'pointer',marginTop:'10px'}} id="showcomments" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18" ><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
                        </div>
                        <div>
                            <div className="d-flex">
                                <div style={{ width: '20%' }}>
                                    <p className={styles.willNotColor + " pl-1"}>20%</p>
                                    <div style={{ height: 4, backgroundColor: '#B84B4B' }}></div>
                                </div>
                                <div style={{ width: '80%' }} className="text-right">
                                    <p className={styles.customDisable + " pr-1"}>80%</p>
                                    <div style={{ height: 4, backgroundColor: '#747474' }}></div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </Container>
        </div>
    );
}

export default Spent;
