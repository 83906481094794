import React from 'react';
import { Card, Container, Button, Modal } from 'react-bootstrap';
import SampleImage from '../images/light-bulb.jpg';
import ProfileImage from '../images/profile.jpg';
import styles from './feed/feed.module.css';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '../components/tab';
import Menu from '../components/menu';
import penLogo from '../images/pen.png';
import linkLogo from '../images/link.png';
import deleteLogo from '../images/delete.png';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Link, useHistory } from 'react-router-dom';
import EmailCopy from '../images/copy.png';
import Comment from '../components/comment';
import DashboardIcon from '../images/dashboard.png';
import DashboardActiveIcon from '../images/dashboardActive.png';
import GoalIcon from '../images/goal.png';
import Create from './create';
import flagIcon from '../images/flag.png';
import tapIcon from '../images/tap.png';
import viewsIcon from '../images/views.png';

function Creations(props) {
    const [isDeleted, SetDelete] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const history = useHistory();
    const [customName, SetCustom] = React.useState("");
    const [commentVisible, SetCommentVisible] = React.useState(false);
    const [commentHeading, SetCommentHeading] = React.useState("");
    const [dashboardActive, SetDashboardActive] = React.useState(false);

    const handleDashboardButtonClick = () => {
        SetDashboardActive(!dashboardActive);
    }

    const handleClose = (e) => {
        if (e !== undefined) {
            if (e.target.name === "delete") {
                SetDelete(true);
            }
        }
        setShow(false);
    };
    const deleteCard = () => {
        SetCustom("delete");
        setShow(true);
    }
    const shareCard = () => {
        SetCustom("share");
        setShow(true);
    }
    const createCard = () => {
        SetCustom("create");
        setShow(true);
    }

    const displayComments = (param) => {
        SetCommentVisible(true);
        SetCommentHeading(param);
    }

    const commentCloseHandler = () => {
        SetCommentVisible(false);
    }

    const renderModal = (param) => {
        switch (param) {
            case "delete":
                return (
                    <div>
                        <p className={styles.feedModalHeader}>Confirm deleting this product?</p>
                        <div className="d-flex text-center mt-3">
                            <button className={styles.buttonDefault} name="delete" style={{ marginRight: 14 }} onClick={handleClose}>Yes</button>
                            <button className={styles.buttonCancel} onClick={handleClose}>No</button>
                        </div>
                    </div>);
            case "share":
                return (
                    <div>
                        <p className={styles.feedModalHeader}>Feature this on your website.</p>
                        <p className={styles.feedModalDesc}>Copy the code below!</p>
                        <span className={styles.feedModalEmailCopy}><img alt="" src={EmailCopy} className={styles.feedEmailLogo} /> {"<p>code here</p>"}</span>
                        <div className="text-center mt-3">
                            <button className={styles.TabActive} onClick={() => { history.push("/feed") }}>FEED</button>
                        </div>
                    </div>
                )
            case "create":
                return (
                    <Create createCloseHandler={handleClose} />
                )
        }
    }

    return (
        <div>
            <Menu />
            <Container className="mt-3">
                <div className="mx-auto">
                    <Tab />
                    <Card body className={"mx-auto mt-5 text-center "+styles.profileCard}>
                        <button className={styles.buttonDefault} style={{ marginRight: 14 }} onClick={createCard}>NEW CREATION</button>
                    </Card>
                    <Card className={"mx-auto mt-3 mb-5 "+styles.profileCard}>
                        <Card.Header className={"d-flex align-items-center " + styles.feedCard}><img src={ProfileImage} alt="" className={styles.feedProfile} /><span className="ml-2">DMatiz</span><div className="ml-auto"><span className="mr-3"><img alt="" src={penLogo} style={{ width: 24 }} /></span><span className="mr-3" style={{ cursor: 'pointer' }} onClick={shareCard}><img src={linkLogo} alt="" style={{ width: 24 }} /></span><span><img src={deleteLogo} alt="" style={{ width: 24 }} /></span></div></Card.Header>
                        <Card.Img variant="top" alt="" src={SampleImage} />
                        <Card.Body>
                            <div className="d-flex align-items-center">
                                <Card.Title className={styles.feedCard}>rock-o-band</Card.Title>
                            </div>
                            <Card.Text className={styles.feedDesc}>
                                I am a very simple card. I am good at containing small bits of information. I am convenient because I require little markup to use effectively.
                            </Card.Text>
                            <div className="d-flex">
                                <span className={styles.tags + " align-self-center"}>IT</span>
                                <span className={styles.tags + " align-self-center"}>SaaS</span>
                                <span className={styles.tags + " align-self-center"}>Movies</span>
                                <Link className="align-items-center d-flex justify-content-center ml-auto" to="/goals" style={{ padding: 10, backgroundColor: '#f0f0f0' }}><img src={GoalIcon} style={{ width: 20 }} alt="" /></Link>
                            </div>
                        </Card.Body>
                        <div>
                            <p className={"text-center " + styles.loadingEngage}>Loading Engagements</p>
                            <LinearProgress />
                        </div>
                    </Card>
                    {(isDeleted === false) ? <Card className={"mx-auto mt-3 mb-5 "+styles.profileCard}>
                        <Card.Header className={"d-flex align-items-center " + styles.feedCard}><img src={ProfileImage} alt="" className={styles.feedProfile} /><span className="ml-2">DMatiz</span><div className="ml-auto"><span className="mr-3" style={{ cursor: 'pointer' }} onClick={shareCard}><img alt="" src={linkLogo} style={{ width: 24 }} /></span><span style={{ cursor: 'pointer' }} onClick={deleteCard}><img alt="" src={deleteLogo} style={{ width: 24 }} /></span></div></Card.Header>
                        <Card.Img variant="top" alt="" src={SampleImage} />
                        <Card.Body>
                            <div className="d-flex align-items-center">
                                <Card.Title className={styles.feedCard}>rock-o-band</Card.Title>
                                <div className={(dashboardActive) ? styles.dashboardActiveLink : styles.dashboardLink} onClick={handleDashboardButtonClick}>{(dashboardActive) ? <img src={DashboardActiveIcon} alt="" style={{ width: 20 }} /> : <img src={DashboardIcon} alt="" style={{ width: 20 }} />}</div>
                            </div>
                            {(dashboardActive) ? <div className="d-flex mb-2">
                                <div className={styles.dashboardCard}>
                                    <div className={styles.dashboardTitle}><img alt="" src={flagIcon} className={styles.dashboardIcon} /><span className={styles.dashboardFigure}>450</span></div>
                                </div>
                                <div className={styles.dashboardCard}>
                                    <div className={styles.dashboardTitle}><img alt="" src={tapIcon} className={styles.dashboardIcon} /><span className={styles.dashboardFigure}>200</span></div>
                                </div>
                                <div className={styles.dashboardCard}>
                                    <div className={styles.dashboardTitle}><img alt="" src={viewsIcon} className={styles.dashboardIcon} /><span className={styles.dashboardFigure}>150</span></div>
                                </div>
                            </div> : null}
                            <Card.Text className={styles.feedDesc}>
                                I am a very simple card. I am good at containing small bits of information. I am convenient because I require little markup to use effectively.
                            </Card.Text>
                            <div className="d-flex">
                                <span className={styles.tags + " align-self-center"}>IT</span>
                                <span className={styles.tags + " align-self-center"}>SaaS</span>
                                <span className={styles.tags + " align-self-center"}>Movies</span>
                                <Link className="align-items-center d-flex justify-content-center ml-auto" to="/goals" style={{ padding: 10, backgroundColor: '#f0f0f0' }}><img alt="" src={GoalIcon} style={{ width: 20 }} /></Link>
                            </div>
                        </Card.Body>
                        <div>
                            <div className="d-flex">
                                <div style={{ width: '60%' }} onClick={() => displayComments("WillNot")}>
                                    <p className={styles.willNotColor + " pl-1"}>60%</p>
                                    <div style={{ height: 4, backgroundColor: '#B84B4B' }}></div>
                                </div>
                                <div style={{ width: '40%' }} onClick={() => displayComments("WillPay")} className="text-right">
                                    <p className={styles.willPayColor + " pr-1"}>40%</p>
                                    <div style={{ height: 4, backgroundColor: '#54A538' }}></div>
                                </div>
                            </div>
                        </div>
                        {(commentVisible) ? <Comment heading={commentHeading} onCloseHandler={commentCloseHandler} /> : null}
                    </Card> : <Card body className={styles.profileCard+" mt-5 " + styles.Message}>
                        <div>Product has been moved to Archive.</div>
                        <p style={{ fontSize: 18, marginTop: 14, color: '#999999' }}>Visit the archives below.</p>
                        <button className={styles.buttonDefault} style={{ marginRight: 14 }} onClick={() => { }}>ARCHIVES</button>
                    </Card>}
                </div>
                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Body className="text-center">
                        {renderModal(customName)}
                    </Modal.Body>
                </Modal>
            </Container>
        </div>
    );
}

export default Creations;
