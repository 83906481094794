import SuccessImage from '../../images/check-mark.png';
import styles from './yourFeedbackIsSubmitted.module.css';

const YourFeedbackIsSubmitted: React.FC = () => {
    return (
        <div>
            <div className="text-center">
                <img src={SuccessImage} />
                <p className={"mt-2 " + styles.yourFeedbackTitle}>Your Feedback is submitted</p>
            </div>
            <div className="d-flex">
                <div style={{ width: '60%' }}>
                    <p className={styles.willNotColor + " pl-1"}>60%</p>
                    <div style={{ height: 4, backgroundColor: '#B84B4B' }}></div>
                </div>
                <div style={{ width: '40%' }} className="text-right">
                    <p className={styles.willPayColor + " pr-1"}>40%</p>
                    <div style={{ height: 4, backgroundColor: '#54A538' }}></div>
                </div>
            </div>
        </div>
    )
}

export default YourFeedbackIsSubmitted;