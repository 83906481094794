import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styles from './visual.module.css';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';
import downArrow from '../images/down.png';
import upArrow from '../images/up.png';


HC_more(Highcharts);
require('highcharts/modules/draggable-points')(Highcharts);  

const Visual = () => {
    const [value, setValue] = React.useState(0);
    const [graphDrag,SetGraphDrag] = React.useState({
      chart: {
          type: 'area',
          scrollablePlotArea:{
              minWidth:700,
              scrollPositionX:1
          }
      },
      title: {
          text: ''
      },
      subtitle: {
          text: ''
      },
      legend:{
        enabled:false
      },
      xAxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'July',
            'Aug',
            'Sept',
            'Oct',
            'Nov',
            'Dec'],
          tickmarkPlacement: 'on',
          title: {
              enabled: false
          },
          labels:{
              overflow:'justify'
          }
      },
      yAxis: {
          title: {
              text: 'in AUD'
          },
          labels: {
              formatter: function () {
                  return '<span>'+(this.value / 1000)+' K</span>';
              }
          }
      },
      tooltip: {
          split: true,
          pointFormat:'{point.y:,.0f}'
      },
      plotOptions: {
          area: {
              stacking: 'normal',
              lineColor: '#666666',
              lineWidth: 1,
              marker: {
                  lineWidth: 1,
                  lineColor: '#666666'
              }
          }
      },
      series: [{
          name: 'Asia',
          dragDrop: {
            draggableY: true
          },
          color:'#f28f43',
          data: [502, 635, 809, 947, 1100, 1230, 1440, 1640, 1720, 1850, 2100, 2230]
      }]
    })


    const [arrValue,SetArrValue] = React.useState({
      chart: {
          type: 'area',
          scrollablePlotArea:{
              minWidth:700,
              scrollPositionX:1
          }
      },
      title: {
          text: ''
      },
      subtitle: {
          text: ''
      },
      legend:{
        enabled:false
      },
      xAxis: {
          categories: [
            '2019',
            '2020',
            '2021',
            '2022'],
          tickmarkPlacement: 'on',
          title: {
              enabled: false
          },
          labels:{
              overflow:'justify'
          }
      },
      yAxis: {
          title: {
              text: 'in AUD'
          },
          labels: {
              formatter: function () {
                  return '<span>'+(this.value / 1000)+' K</span>';
              }
          }
      },
      tooltip: {
          pointFormat:'{point.y:,.0f}'
      },
      plotOptions: {
          area: {
              stacking: 'normal',
              lineColor: '#666666',
              lineWidth: 1,
              marker: {
                  lineWidth: 1,
                  lineColor: '#666666'
              }
          }
      },
      series: [{
          name: 'Asia',
          dragDrop: {
            draggableY: true
          },
          data: [201, 325, 409, 547],
          color:'#a6c96a',
      }]
    })

  const [lineGraph,SetLine] = React.useState({
    chart: {
        type: 'column',
    },
    title: {
        text: ''
    },
    subtitle: {
        text: ''
    },
    xAxis: {
        categories: [
            'Jan 2020',
            'Feb 2020',
            'Mar 2020',
            'Apr 2020',
            'May 2020',
            'Jun 2020',
            'Jul 2020',
            'Aug 2020',
            'Sep 2020',
            'Oct 2020',
            'Nov 2020',
            'Dec 2020'
        ],
    },
    legend:{
      enabled:false
    },
    yAxis: {
        title: {
            text: 'Percentage'
        },
        labels: {
            formatter: function () {
                return '<span>'+(this.value)+' %</span>';
            }
        }
    },
    tooltip: {
        pointFormat: '<span className={styles.graph} style="font-size:10px">{point.y:,.1f}%</span>',
        // useHTML: true
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    series: [{
        name: '',
        dragDrop: {
          draggableY: true
        },
        data: [4.4, 3.2, 3.5, 3.7, 5.6, 7.5, 5.4, 6.4, 4.6, 3.1, 4.8, 5.1]

    }]
})
    const handleChange = (event,newValue) =>{
        setValue(newValue);
    }

    const chartSwitch = (key) =>{
      switch (key) {
        case 0:
          return (
          <div>
            <p className={styles.title}>Monthly Recurring Revenue</p>
            <div><span className={styles.amount}>$3908 </span> <span className={styles.percentage}>32.2%</span> <img alt="down" style={{ width: 12 }} src={downArrow} /></div>
                  
            <HighchartsReact
              constructorType={"chart"}
              highcharts={Highcharts}
              options={graphDrag}
            />
          </div>
          );
          break;
        case 1:
          return (
            <div>
              <p className={styles.title}>Annual Recurring Revenue</p>
              <div><span className={styles.amount}>$542 </span> <span className={styles.percentageUp}>33.7%</span> <img alt="up" style={{ width: 12 }} src={upArrow} /></div>
                      
              <HighchartsReact
                constructorType={"chart"}
                highcharts={Highcharts}
                options={arrValue}
                style={{marginTop:'1.5rem'}}
              />
            </div>
          );
          break;
        case 2:
          return (
            <div>
              <p className={styles.title}>Churn Rate</p>
              {/* <div><span className={styles.amount}>$3908 </span> <span className={styles.percentage}>32.2%</span> <img alt="down" style={{ width: 12 }} src={downArrow} /></div> */}
              <HighchartsReact
                constructorType={"chart"}
                highcharts={Highcharts}
                options={lineGraph}
                style={{marginTop:20}}
              />   
            </div>
          )
          break;
      
        default:
          break;
      }
    }

    return (
        <div>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                className={styles.tabbed}
                aria-label="icon tabs example"
            >
                <Tab style={{minWidth:'auto'}} label="MRR" />
                <Tab style={{minWidth:'auto'}} label="ARR" />
                <Tab style={{minWidth:'auto'}} label="Churn Rate" />
            </Tabs>
            {chartSwitch(value)}
            
        </div>
    )
}

export default Visual;