import React from 'react';
import { Card, Container, Button, Modal, Media, ProgressBar, Popover, OverlayTrigger } from 'react-bootstrap';
import SampleImage from '../../images/light-bulb.jpg';
import { useHistory } from 'react-router-dom';
import { firestore } from '../../auth/firebase';
import ProfileImage from '../../images/profile1.jpeg';
import SuccessImage from '../../images/check-mark.png';
import styles from './feed.module.css';
import heartLogo from '../../images/heart.png';
import { useDispatch } from 'react-redux';
import ProductBar from '../../components/productbar';
import Feedback from '../../components/feedback/feedback';
import BackImage from '../../images/back-arrow.png';
import Visual from '../../components/visual';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import FeedbackIcon from '@material-ui/icons/Feedback';
import SimpleReactValidator from 'simple-react-validator';
import { switchProfile } from '../../redux-store/action';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import customStyles from './custom.module.css';
import NewLogo from '../../images/logo.png';
import Calendar from 'react-calendar';
import GoalFlag from '../../components/goalFlag';
import ReactGA from 'react-ga';
import Slider from '@material-ui/core/Slider';
import EmailCopy from '../../images/copy.png';
import { IconButton, Link, ListItemSecondaryAction } from '@material-ui/core';
import { Animated } from 'react-animated-css';
import ProfileImageSecond from '../../images/profile2.jpeg';
import { Equalizer, Label, MoreHoriz, PermIdentity, FlagOutlined } from '@material-ui/icons';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LoomIntegrationComponent from '../../components/loomIntegrationComponent';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ThankYou from '../thankyou';
import Party from '../../images/confetti.png';
import WillPayRangeSlider from '../../components/willPaySlider';
import { Checkbox, MuiThemeProvider, FormControlLabel, Typography } from '@material-ui/core';
import BookSlot from './bookslot';
import YourFeedbackIsSubmitted from '../../components/yourFeedbackIsSubmitted';
import FeedBackThankYou from '../../components/feedBackThankYou';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    templateSwitch: {
        margin: 0
    }
});

const muiTheme = createMuiTheme({
    overrides: {
        MuiSlider: {
            thumb: {
                color: "#6a7bff",
            },
            track: {
                color: '#6a7bff'
            },
            rail: {
                color: 'black'
            }
        }
    }
});

const marks = [
    {
        value: 0,
        label: '$0',
    },
    {
        value: 100,
        label: '$100',
    },
];

function valuetext(value) {
    return `${value}°C`;
}

function FeedLayout(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [willPay, SetWillPay] = React.useState(false);
    const [willNot, SetWillNot] = React.useState(false);
    const [submitted, SetSubmit] = React.useState(false);
    const [isSubscribe, setSubscribe] = React.useState(false);
    const [calendarValue, onCalendarChange] = React.useState(new Date());
    const [isCalendar, setCalendarFlag] = React.useState(true);
    const [auth, setAuth] = React.useState<{ user?: any }>({});
    const history = useHistory();
    const [userEmail, SetUserEmail] = React.useState("");
    const [value, setValue] = React.useState([20, 37]);
    const [selectedValue, setSelectedValue] = React.useState('Yes');
    const [show, setShow] = React.useState(false);
    const [slotPage, setSlotPage] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [switchProfiler, setSwitchProfile] = React.useState("1");
    const [chartVisible, SetChart] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showMore, setShowMore] = React.useState(false);
    const [willPayText, setWillPaytext] = React.useState("");
    const [willNotText, setWillNotText] = React.useState("");
    const [fadeChecked, setFadeChecked] = React.useState(false);
    const [check, setSettingsMenuCheck] = React.useState<{ checkedB?: boolean, checkedA?: boolean, checkedC?: boolean, checkedD?: boolean }>({
        checkedA: false
    });
    const [checked, SetChecked] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedC: true,
        checkedD: true
    })

    const [isGraph, setGraph] = React.useState(false);

    const handleClose = () => {
        setShow(false);
        SetSubmit(true);
        SetWillNot(false);
        SetWillPay(false);
    };

    const theme = createMuiTheme({
        typography: {
            fontFamily: 'NunitoSemi'
        }
    });


    const simpleValidator = React.useRef(new SimpleReactValidator());
    const handleShow = async () => {
        const willPayComment = willPayText.trim();
        const willNotComment = willNotText.trim();
        try {
            if (willPay && willPayComment != "") {
                const res = await firestore.collection("willpay").add({
                    willPayComment: willPayText,
                    lowestPrice: value[0],
                    highestPrice: value[1]
                })
                setShow(true)
            }
            if (willNot && willNotComment != "") {
                const res = await firestore.collection("willnot").add({
                    feedback: willNotText
                })
                setShow(true)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleCheckChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const showWillPayOptions = () => {
        SetWillPay(true);
        SetWillNot(false);
        setShowMore(false);
    };

    const showWillNotOptions = () => {
        SetWillNot(true);
        SetWillPay(false);
        setShowMore(false);
    }

    const profileSwitcher = () => {
        dispatch(switchProfile(false));
    }

    const handleSettingMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    }

    const handleSettingClose = (event) => {
        setAnchorEl(null);
    }

    const handleGraphChange = () => {
        setGraph(!isGraph);
    }

    const handleSettingsChange = (event) => {
        setSettingsMenuCheck({ ...check, [event.target.name]: event.target.checked });
    }

    const emailHandler = (e) => {
        SetUserEmail(e.target.value);
    }
    const addEmail = async (e) => {
        e.preventDefault();
        try {
            if (userEmail !== "" && simpleValidator.current.allValid()) {
                const res = await firestore.collection("users").add({
                    email: userEmail
                })
                setSubscribe(true);
                // history.push("/thankyou");
                SetUserEmail("");
            }
        } catch (e) {
            console.log(e);
        }
    }

    const previousPage = () => {
        setSubscribe(false);
    }


    const bookASlot = () => {

        setCalendarFlag(true);
        setSwitchProfile("1");
        setSlotPage(false);
        SetChecked({ ...checked, checkedC: true });
    }

    const willPayInputHandler = (e) => {
        setWillPaytext(e.target.value);
    }

    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.common.white,
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    const willNotInputHandler = (e) => {
        setWillNotText(e.target.value);
    }

    const logoImageOff = () => {
        setFadeChecked(false);
    }

    const logoImageOn = () => {
        setFadeChecked(true);
    }

    React.useEffect(() => {
        if (localStorage.getItem('profile')) {
            setAuth(JSON.parse(localStorage.getItem('profile')));
        } else {
            history.push('/');
        }
    }, []);

    const popOver = (
        <Popover id={`popover-basic`} style={{ width: 360, maxWidth: '100%' }}>
            <Popover.Content>
                <Media>
                    <img
                        width={64}
                        height={64}
                        className="align-self-start mr-3 rounded-circle"
                        src={ProfileImage}
                        alt="Generic placeholder"
                    />
                    <Media.Body>
                        <h5 className={styles.profileTitle}>Adam Lee</h5>
                        <p className={styles.profileDesc}>Music Enthusiast</p>
                        {/* <div className="d-flex">
                            <span className={"mr-auto " + styles.profileProgress}>ARR</span>
                            <span className={"ml-auto " + styles.profileProgress}>10 Mi</span>
                        </div>
                        <ProgressBar style={{ height: '0.3rem' }} now={60} /> */}
                        <div className="mt-3">
                            <button className={styles.profileButton}>My Creations</button>{' '}
                            <button className={styles.profileButton}>Timeline</button>{' '}
                            <button className={styles.profileButton}>Updates</button>
                        </div>
                    </Media.Body>
                </Media>
            </Popover.Content>
        </Popover>
    );

    if (isSubscribe) {
        return <ThankYou setPreviousPage={previousPage} />
    }

    else {
        return (
            <Container className="mt-3">
                <div className="mx-auto">
                    <Card className={"mx-auto mt-3 mb-3 " + styles.profileCard}>
                        {(chartVisible) ? <div>
                            {/* <Card.Img variant="top" src={SampleImage} />
                         */}
                            <div className="d-flex" style={{ padding: '1.25rem' }}>
                                <div style={{display:'flex'}}>
                                    <a target="_blank" href="https://twitter.com/harshaunknownu"><img src={ProfileImage} className={styles.feedWillPayProfile} /></a><a target="_blank" href="https://twitter.com/seebiscut"><img src={ProfileImageSecond} className={styles.feedWillPayProfile} /></a>
                                </div>
                                <div style={{marginLeft:'130px'}}>
                                    <img src={NewLogo} style={{ width: 148 }} />
                                </div>

                                <div className="ml-auto">
                                    {(check.checkedA && switchProfiler === "1") &&
                                        <IconButton
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            onClick={() => { setSwitchProfile("2") }}
                                        >
                                            <DateRangeIcon className={customStyles.feedbackColor} />
                                        </IconButton>}

                                    {(check.checkedA && switchProfiler === "2") &&
                                        <IconButton
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            onClick={() => { setSwitchProfile("1") }}
                                        >
                                            <PermIdentity className={customStyles.feedbackColor} />
                                        </IconButton>}

                                    {(check.checkedB && switchProfiler === "1") &&
                                        <IconButton
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            onClick={() => { setSwitchProfile("3") }}
                                        >
                                            <Equalizer className={customStyles.feedbackColor} />
                                        </IconButton>
                                    }
                                    {(check.checkedB && switchProfiler === "3") &&
                                        <IconButton
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            onClick={() => { setSwitchProfile("1") }}
                                        >
                                            <PermIdentity className={customStyles.feedbackColor} />
                                        </IconButton>
                                    }

                                    {(check.checkedC && switchProfiler === "1") ?
                                        <IconButton
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            onClick={() => { setSwitchProfile("4") }}
                                        >
                                            <FeedbackIcon className={customStyles.feedbackColor} />
                                        </IconButton> : null}

                                    {(check.checkedC && switchProfiler === "4") ?
                                        <IconButton
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            onClick={() => { setSwitchProfile("1") }}
                                        >
                                            <PermIdentity className={customStyles.feedbackColor} />
                                        </IconButton> : null}

                                    {(check.checkedD && switchProfiler === "1") ?
                                        <IconButton
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            onClick={() => { setSwitchProfile("5") }}
                                        >
                                            <FlagOutlined className={customStyles.feedbackColor} />
                                        </IconButton> : null}

                                    {(check.checkedD && switchProfiler === "5") ?
                                        <IconButton
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            onClick={() => { setSwitchProfile("1") }}
                                        >
                                            <PermIdentity className={customStyles.feedbackColor} />
                                        </IconButton> : null}


                                    {(check.checkedD && switchProfiler === "5") ?
                                        <span style={{ padding: 14 }}>
                                            <AntSwitch checked={isGraph} onChange={handleGraphChange} name="isGraph" /> </span> : null}
                                    {/* <IconButton onClick={profileSwitcher}><PermIdentity /></IconButton> */}

                                    <IconButton
                                        onClick={handleSettingMenu}>
                                        
                                    </IconButton>
                                    <MuiThemeProvider theme={theme}>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleSettingClose}
                                        >
                                            <MenuItem>
                                                <FormControlLabel
                                                    className={classes.templateSwitch}
                                                    control={<Checkbox checked={check.checkedA || false} onChange={handleSettingsChange} name="checkedA" />}
                                                    label={<Typography>Calendar</Typography>}
                                                />
                                            </MenuItem>
                                            <MenuItem>
                                                <FormControlLabel
                                                    className={classes.templateSwitch}
                                                    control={<Checkbox checked={check.checkedB || false} onChange={handleSettingsChange} name="checkedB" />}
                                                    label={<Typography>Charts</Typography>}
                                                />
                                            </MenuItem>
                                            <MenuItem>
                                                <FormControlLabel
                                                    className={classes.templateSwitch}
                                                    control={<Checkbox checked={check.checkedC || false} onChange={handleSettingsChange} name="checkedC" />}
                                                    label={<Typography>Feedback</Typography>}
                                                />
                                            </MenuItem>
                                            <MenuItem>
                                                <FormControlLabel
                                                    className={classes.templateSwitch}
                                                    control={<Checkbox checked={check.checkedD || false} onChange={handleSettingsChange} name="checkedD" />}
                                                    label={<Typography>Goal</Typography>}
                                                />
                                            </MenuItem>
                                        </Menu>
                                    </MuiThemeProvider>
                                </div>
                            </div>
                            {switchProfiler === "1" ? <LoomIntegrationComponent /> : null}
                            {switchProfiler === "1" ? <Card.Body>
                                {/* <Card.Text className={styles.feedDesc}>We <img src={heartLogo} style={{ width: 24 }} />  MicroSaas!</Card.Text> */}
                                <Card.Text className={styles.feedHighlightText}>
                                    <h5>Insta cards for MicroSaaS businesses for customer validation.</h5>
                                </Card.Text>
                                <Card.Text className={styles.feedDesc}>
                                    If you are a MicroSaaS focused creator, or an aspirant, Join Us. We like to interact with interesting creators, subscribe here, we will notify you once we start opening up the app for usage.
                                    <span><Link
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                            setShowMore(!showMore)
                                            SetWillNot(false)
                                            SetWillPay(false)
                                        }}
                                    >{showMore === false && "(... Show more)"}</Link></span>
                                </Card.Text>
                                <div className={showMore ? styles.feedSecondContentLayout : styles.feedContentLayout}>
                                    <Card.Text className={styles.feedDesc}>
                                        <span><Link
                                            component="button"
                                            variant="body2"
                                            onClick={() => {
                                                setShowMore(!showMore)
                                                SetWillNot(false)
                                                SetWillPay(false)
                                            }}
                                        >{showMore && "Show less"}</Link></span>
                                    </Card.Text>
                                    <div>
                                        <span className={styles.tags}>IT</span>
                                        <span className={styles.tags}>Micro-SaaS</span>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </Card.Body> : null}
                            {/* new added  */}

                            {(switchProfiler === "2") ?
                                <div>
                                    {(slotPage) ? <BookSlot date={calendarValue} bookSlotHandler={bookASlot} /> : <div className="p-3">
                                        {(isCalendar) ?
                                            <List className={classes.root}>
                                                <ListItem
                                                    button
                                                    selected={selectedIndex === 0}
                                                    onClick={(event) => handleListItemClick(event, 0)}
                                                >
                                                    <ListItemText className={customStyles.listItem} primary={<Typography style={{ width: '60%' }}>Hackathon</Typography>} secondary="Jan 9, 2021 8:30 AM" />
                                                    <ListItemSecondaryAction style={{ right: 35 }}>
                                                        5 Minutes
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <ListItem
                                                    button
                                                    selected={selectedIndex === 1}
                                                    onClick={(event) => handleListItemClick(event, 1)}>
                                                    <ListItemText className={customStyles.listItem} primary={<Typography style={{ width: '60%' }}>Design Mockup Review</Typography>} secondary="Jan 7, 2021 9:30 PM" />
                                                    <ListItemSecondaryAction style={{ right: 35 }}>
                                                        10 Minutes
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <ListItem
                                                    button
                                                    selected={selectedIndex === 2}
                                                    onClick={(event) => handleListItemClick(event, 2)}>
                                                    <ListItemText className={customStyles.listItem} primary={<Typography style={{ width: '60%' }}>Data Intensive application Bootcamp</Typography>} secondary="July 20, 2021 6:45 PM" />
                                                    <ListItemSecondaryAction style={{ right: 35 }}>
                                                        20 Minutes
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <div className="d-flex justify-content-center">
                                                    <button className={customStyles.buttonDefault} onClick={() => { setCalendarFlag(false) }}>Book An Event</button>
                                                </div>
                                            </List> : <div className="mt-3">
                                                <button title="Back to Events" onClick={() => { setCalendarFlag(true) }} className={customStyles.backButton}><img alt="back" src={BackImage} className={customStyles.backIcon} /></button>
                                                <Calendar
                                                    className={customStyles.calendar}
                                                    onChange={onCalendarChange}
                                                    value={calendarValue}
                                                    minDate={new Date()}
                                                />
                                                <div className="d-flex justify-content-center">
                                                    <button className={customStyles.buttonDefault} onClick={() => { setSlotPage(true) }}>Select</button>
                                                </div>
                                            </div>}
                                    </div>}
                                </div> : null}

                            {(switchProfiler === "3") ?
                                <div className="p-3">
                                    <ProductBar />
                                    <Visual />
                                </div> : null
                            }

                            {(switchProfiler === "4") ?
                                <div className="p-3">
                                    <Feedback feedbackHandler={() => { setSwitchProfile("1") }} />
                                </div> : null
                            }

                            {(switchProfiler === "5") ?
                                <div className="p-3">
                                    <GoalFlag showGraph={isGraph} />
                                </div> : null
                            }

                            {(submitted === false) ? <Card.Body className="d-flex">
                                <button className={(willPay ? styles.willPaySelected : styles.willpay) + " mr-auto"} onClick={showWillPayOptions}>WILL PAY</button>
                                <button className={willNot ? styles.willnotSelected : styles.willnot} onClick={showWillNotOptions}>WILL NOT</button>
                            </Card.Body> : <YourFeedbackIsSubmitted />}
                            {willPay ? <Card.Body className="mt-4">
                                <ThemeProvider theme={muiTheme}>
                                    <WillPayRangeSlider />
                                    <textarea onChange={willPayInputHandler} value={willPayText} className={"ml-auto form-control"} placeholder="comments" />
                                    <div className="text-center mt-3">
                                        <button className={styles.TabActive} onClick={handleShow}>THANK YOU</button>
                                    </div>
                                </ThemeProvider>
                            </Card.Body> : null}
                            {willNot ? <Card.Body>
                                <textarea onChange={willNotInputHandler} value={willNotText} className={"ml-auto mb-3 form-control"} placeholder="Help us Understand...Why?" />
                                <div className="text-center mt-3">
                                    <button className={styles.TabActive} onClick={handleShow}>SEND FEEDBACK</button>
                                </div>
                            </Card.Body> : null}
                        </div> : <Card.Body>
                            <Visual />
                        </Card.Body>}
                    </Card>
                    <Card className={"mx-auto mt-3 mb-3 " + styles.profileCard}>
                        <Card.Body>
                            <Card.Text className={styles.feedDesc}>
                                "Inviting creators who can be `early users` of WillPay" <img src={Party} style={{ width: 22 }} />
                            </Card.Text>
                            <div className="d-flex my-3"><input value={userEmail} onChange={emailHandler} className={"ml-auto " + styles.homeInput} name="joining_list" type="text" placeholder="Join the Queue (Your Email ID)" /> <button className={styles.regular} onClick={addEmail}>SUBSCRIBE</button></div>
                            <div style={{ fontSize: 12, color: 'ff0000' }}>{simpleValidator.current.message('joining_list', userEmail, 'required|email')}</div>
                        </Card.Body>
                    </Card>
                </div>
                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Body className="text-center p-4">
                        <FeedBackThankYou handleClose={handleClose} />
                    </Modal.Body>
                </Modal>
            </Container>
        );
    }
}

export default FeedLayout;
