import React from 'react';
import Menu from '../../components/menu';
import Profile from '../feed/feedlayout5';
import './profile.css';
// import FeedLayout from './feedlayout3';
import { useSelector } from 'react-redux';
import Product from '../feed/feedlayout';
// import Product from './feedWillPayWindow'; 
import { Rootstate } from '../../interface';
// import FeedLayout from './feedlayout'; 
import { Link } from 'react-router-dom';
import Menu2 from '../../components/menu2';
function Profiles(props) {
    const profile = useSelector<Rootstate>(state=>state.profile)
    
    return (
        <div>
            
            <Menu2/>

            <Profile/></div>
            

    );
}

export default Profiles;
