import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import styles from './feedback.module.css';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import ReactPlayer from 'react-player';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { AccessTime } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    arrowIcon: {
        cursor: 'pointer'
    },
    clock: {
        fontSize:12
    }
}));

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'NunitoSemi'
        ] as any
    }
})


const FeedbackList = (props) => {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    return (
        <ThemeProvider theme = {theme}>
            { (selectedIndex === 1) ?
            <div>
                <ArrowBackIcon className={"mt-3 "+classes.arrowIcon} onClick={()=>{ setSelectedIndex(null)}} />
                <ReactPlayer
                    url='https://www.youtube.com/watch?v=nTdHv1zsLiY'
                    width="100%"
                    className="mt-3"
                    height="360px"
                    controls={true}
                />
                <div className="d-flex mt-4">
                    <Avatar alt="Remy Sharp" src="https://material-ui.com/static/images/avatar/1.jpg"/>
                    <div className={styles.chatList}>
                        <p className={styles.feedbackText}>It would be cool jazzy look for dark theme!</p>
                        <p className={"text-right mb-0 "+styles.timeStamp}><AccessTime className={classes.clock} /> 8:30 PM</p>
                    </div>
                </div>
                <div className="d-flex mt-3">
                    <Avatar alt="Remy Sharp" src="https://material-ui.com/static/images/avatar/1.jpg"/>
                    <div className={styles.chatList}>
                        <p className={styles.feedbackText}>Need attention for this app.</p>
                        <p className={"text-right mb-0 "+styles.timeStamp}><AccessTime className={classes.clock} /> 7:30 PM</p>
                    </div>
                </div>
                <div className="d-flex mt-3">
                    <Avatar alt="Remy Sharp" src="https://material-ui.com/static/images/avatar/1.jpg"/>
                    <div className={styles.chatList}>
                        <p className={styles.feedbackText}>The application is not compatible with my current configuration.</p>
                        <p className={"text-right mb-0 "+styles.timeStamp}><AccessTime className={classes.clock} /> 4:30 PM</p>
                    </div>
                </div>
            </div> : <List className={classes.root}>
            <ListItem alignItems="flex-start" selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 0)}>
                <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="More Update on Product?"
                    className={styles.feedbackHeading}
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                                Ali Connors
                            </Typography>
                            {" — Can we use the popup feature."}
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start" selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1)}>
                <ListItemAvatar>
                    <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Dark Theme"
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                                to Scott
                            </Typography>
                            {" — It would be cool jazzy look for dark theme"}
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start" selected={selectedIndex === 2} onClick={(event) => handleListItemClick(event, 2)}>
                <ListItemAvatar>
                    <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Plugins Break"
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                                Sandra Adams
                            </Typography>
                            {' — Feature not compatible with my browser'}
                        </React.Fragment>
                    }
                />
            </ListItem>
        </List>}
        </ThemeProvider>
    )
}

export default FeedbackList;