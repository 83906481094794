const domain = [
    {value:'saas',label:'saas'},
    {value:'privacy',label:'privacy'},
    {value:'design',label:'design'}
];

const target = [
    {value:'dating',label:'dating'},
    {value:'marketing',label:'marketing'},
    {value:'Ai',label:'Ai'}
];

const niche_target = [
    {value:'singles',label:'singles'},
    {value:'dating fanatics',label:'dating fanatics'},
    {value:'vegetarians',label:'vegetarians'}
];

export default {domain, target,niche_target};