import React from 'react';
import styles from './productbar.module.css';
import CompanyImage1 from '../images/company1.png';
import CompanyImage2 from '../images/company2.png';
import CompanyImage3 from '../images/company3.png';
import CompanyImage4 from '../images/company4.png';
import CompanyImage5 from '../images/slack.jpg';
  
const ProductBar = () => {
    return (
        <div style={{margin:'2rem 0.5rem', display:'flex'}}>
            <div className={styles.grid} onClick={()=>{}} style={{ backgroundImage: "url(" + CompanyImage1 + ")" }}></div>
            <div className={styles.grid} onClick={()=>{}} style={{ backgroundImage: "url(" + CompanyImage2 + ")" }}></div>
            <div className={styles.grid} onClick={()=>{}} style={{ backgroundImage: "url(" + CompanyImage3 + ")" }}></div>
            <div className={styles.grid} onClick={()=>{}} style={{ backgroundImage: "url(" + CompanyImage4 + ")" }}></div>
            <div className={styles.grid} onClick={()=>{}} style={{ backgroundImage: "url(" + CompanyImage5 + ")" }}></div>
        </div>
    )
}

export default ProductBar;