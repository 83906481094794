import React from 'react';
import Webcam from 'react-webcam';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch } from 'react-redux';
import { switchFeedback } from '../redux-store/action';

const useStyles = makeStyles((theme) => ({
    captureButton: {
        backgroundColor: '#6a7bff',
        color: '#ffffff'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    }
}));

const CreateFeedback = (props) => {
    const classes = useStyles();
    const webcamRef = React.useRef(null);
    const mediaRecorderRef = React.useRef(null);
    const [capturing, setCapturing] = React.useState(false);
    const [recordedChunks, setRecordedChunks] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        // if (isLoadStream === false) {
        //     setTimeout(() => {
        //         console.log("Unable to stream Video");
        //     }, 10000);
        // }
    }, []);

    const handleStartCaptureClick = React.useCallback(() => {
        setCapturing(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: "video/webm"
        });
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        mediaRecorderRef.current.start();
    }, [webcamRef, setCapturing, mediaRecorderRef]);

    const handleDataAvailable = React.useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );

    const handleStopCaptureClick = React.useCallback(() => {
        mediaRecorderRef.current.stop();
        setCapturing(false);
    }, [mediaRecorderRef, webcamRef, setCapturing]);

    const handleDownload = React.useCallback(() => {
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                type: "video/webm"
            });
            const url = URL.createObjectURL(blob);
            const a: any = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = "react-webcam-stream-capture.webm";
            a.click();
            window.URL.revokeObjectURL(url);
            setRecordedChunks([]);
        }
    }, [recordedChunks]);

    const handleClose = () => {
        setOpen(false);
    }

    const closeFeedbackHandler = () => {
        dispatch(switchFeedback(true));
    }

    return (
        <>
            <Webcam height={360} width={450} onUserMedia={() => {}} audio={false} ref={webcamRef} />
            {capturing ? (
                <Button onClick={handleStopCaptureClick} className={classes.captureButton}>Stop Capture</Button>
            ) : (
                <Button onClick={handleStartCaptureClick} className={classes.captureButton} disabled={false}>Start Capture</Button>
            )}
            {recordedChunks.length > 0 && (
                <Button onClick={handleDownload}>Download</Button>
            )}
            <hr />
            <div className="text-center">
                <textarea className="mt-3 form-control" name="product_desc" placeholder="Provide your feedback here"></textarea>
                <Button onClick={() => { setOpen(true) }} className={classes.captureButton + " mt-2"}>SUBMIT</Button>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Hurrayyy"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We have sent your feedback to the creator!
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeFeedbackHandler} color="primary">
                        FEED
                </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CreateFeedback;