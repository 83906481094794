import React, { useEffect } from 'react';
import styles from './tab.module.css';
import { useHistory,useLocation } from 'react-router-dom';

function Tab(props){
    const history = useHistory();
    const location = useLocation();
    const [text,ChangeText] = React.useState("MY SUBMISSIONS");
    useEffect(()=>{
        if(location.pathname==="/mine" || location.pathname==="/" || location.pathname==="/feed"){
            ChangeText("MY SUBMISSIONS");
        }
        if(location.pathname==="/creations"){
            ChangeText("MY CREATIONS");
        }
    },[]);
    const toggleEvent = () =>{
        if(location.pathname==="/mine"){
            history.push("/creations");
        }

        if(location.pathname==="/creations" || location.pathname==="/" || location.pathname==="/feed"){
            history.push("/mine");
        }
    }
    const handleEnterHover = () =>{
        if(location.pathname==="/mine"){
            ChangeText("MY CREATIONS");
        }
        if(location.pathname==="/creations"){
            ChangeText("MY SUBMISSIONS");
        }
    }
    const handleLeaveHover = () =>{
        if(location.pathname==="/mine"){
            ChangeText("MY SUBMISSIONS");
        }
        if(location.pathname==="/creations"){
            ChangeText("MY CREATIONS");
        }
    }
    return(    
        <div className="text-center">
            <button className={(location.pathname==="/" ||location.pathname==="/feed" )?styles.TabActive:styles.TabInactive} onClick={()=>{history.push('/feed')}}>FEED</button>{'  '}
            <button className={(location.pathname==="/mine" || location.pathname==="/creations")?styles.TabActive:styles.TabInactive} onMouseEnter={handleEnterHover} onMouseLeave={handleLeaveHover} onClick={toggleEvent}>{text}</button>
        </div>
    )
}

export default Tab;