import React from 'react';
import Slider from '@material-ui/core/Slider';
import { Card, Container, Button, Modal, Media, ProgressBar, Popover, OverlayTrigger } from 'react-bootstrap';
import styles from './slider.module.css';

const WillPayRangeSlider = () => {
    const [value, setValue] = React.useState([20, 37]);
    function valuetext(value) {
        return `${value}°C`;
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const marks = [
        {
            value: 0,
            label: '$0',
        },
        {
            value: 100,
            label: '$100',
        },
    ];
    return (
        <div>
            <Slider
                value={value}
                onChange={handleChange}
                aria-labelledby="range-slider"
                getAriaValueText={valuetext}
                valueLabelDisplay="on"
                marks={marks}
            />
            <div className={styles.sliderPriceText + " text-right"}>(*Slider indicate price Per Month)</div>
        </div>
    )
}

export default WillPayRangeSlider;