import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CompanyImage1 from '../../images/company1.png';
import CompanyImage2 from '../../images/company2.png';
import CompanyImage3 from '../../images/company3.png';
import CompanyImage4 from '../../images/company4.png';
import CompanyImage5 from '../../images/slack.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { switchProfile, switchFeedback } from '../../redux-store/action';
import PlusImage from '../../images/plus.png';
import BackImage from '../../images/back-arrow.png';
import Switch from '@material-ui/core/Switch';
import IndianFlag from '../../images/india.png';
import customStyles from './custom.module.css';
import Calendar from 'react-calendar';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from 'react-router-dom';
import CardContent from '@material-ui/core/CardContent';
import 'react-calendar/dist/Calendar.css';
import BookSlot from './bookslot';
import './custom.css';
import { Equalizer, ListAlt, MoreHoriz, PermIdentity, FlagOutlined, Flag } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Checkbox, createMuiTheme, FormControlLabel, IconButton, ListItemSecondaryAction, Typography, MuiThemeProvider, StylesProvider } from '@material-ui/core';
import Visual from '../../components/visual';
import ProductBar from '../../components/productbar';
import Feedback from '../../components/feedback/feedback';
import MenuBar from '../../images/menu.png';
import FeedbackIcon from '@material-ui/icons/Feedback';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { Rootstate } from '../../interface';
import GoalFlag from '../../components/goalFlag';
import Goal from '../goal/goal';
import ProfileImage from '../../images/profile.jpg';
import '../profile/profile.css';
import styles from '../feed/feed.module.css';
import { useState, useEffect } from "react";
import Earned from '../earned';

import 'bootstrap/dist/css/bootstrap.min.css';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown'
const useStyles = makeStyles({
    root: {
        minHeight: 480,
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: '1.5rem'
    },
    rounded: {
        borderRadius: 30,
        width: 64,
        marginRight: 'auto'
    },
    profile: {
        borderRadius: 30,
        width: 48,
        marginRight: 'auto'
    },
    spacing: {
        paddingTop: '1.25rem'
    },
    templateSwitch: {
        margin: 0
    }
});

function FeedLayout5(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [auth, setAuth] = React.useState<{ user?: any }>({});
    const [calendarValue, onCalendarChange] = React.useState(new Date());
    const [isCalendar, setCalendarFlag] = React.useState(true);
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [switchProfiler, setSwitchProfile] = React.useState("1");
    const [slotPage, setSlotPage] = React.useState(false);
    const FeedbackList = useSelector<Rootstate>(state => state.isFeedBackList);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [check, setSettingsMenuCheck] = React.useState<{ checkedB?: boolean, checkedA?: boolean, checkedC?: boolean, checkedD?: boolean }>({
        checkedA: false
    })
    const [isGraph, setGraph] = React.useState(false);

    const theme = createMuiTheme({
        typography: {
            fontFamily: 'NunitoSemi'
        }
    });

    const profileSwitcher = () => {
        dispatch(switchProfile(true));
    }

    const [checked, SetChecked] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedC: true,
        checkedD: true
    })

    const handleChange = (event) => {
        SetChecked({ ...checked, [event.target.name]: event.target.checked });
        if (checked.checkedC) {
            setSwitchProfile("2");
        } else {
            setSwitchProfile("1");
        }
    }

    const handleGraphChange = () => {
        setGraph(!isGraph);
    }

    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.common.white,
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    const bookASlot = () => {

        setCalendarFlag(true);
        setSwitchProfile("1");
        setSlotPage(false);
        SetChecked({ ...checked, checkedC: true });
    }

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    }

    const handleSettingMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleSettingClose = (event) => {
        setAnchorEl(null);
    }

    const handleSettingsChange = (event) => {
        setSettingsMenuCheck({ ...check, [event.target.name]: event.target.checked });
    }

    React.useEffect(() => {
        if (localStorage.getItem('profile')) {
            setAuth(JSON.parse(localStorage.getItem('profile')));
        } else {
            history.push('/');
        }
    }, []);
//{(Object.keys(auth).length > 0) ? auth.user.profile_image : ""}
const [inputValue, setInputValue] = useState("Bengaluru, Karnataka | {map needs to be added} India");
const onChangeHandler = event => {
    setInputValue(event.target.value);
 };

 React.useEffect(() => {
    const lu=document.getElementById('locationupdate') as HTMLInputElement;
    const ss=document.getElementById('setstatus') as HTMLElement;
    const op=document.getElementById('input-status') as HTMLInputElement;
    if(ss){ss.addEventListener('click',function(event){
        if(lu){
        lu.innerHTML=op.value}
    ss.style.display='none';
op.style.display='none';})
    }
    const l=document.getElementById('location') as HTMLElement;
    const lo=document.getElementById('addl') as HTMLElement;
    if(l){l.addEventListener('click',function(event){
        lo.style.display='block';
        ss.style.display='block';
        op.style.display='block';
    })

    }

    const clickdiv=document.getElementById('clickdiv') as HTMLElement;
    const sixty=document.getElementById('sixty') as HTMLElement;
    const hundred=document.getElementById('hundred') as HTMLElement;
    const hundredn=document.getElementById('hundredn') as HTMLElement;
    if(clickdiv){
        clickdiv.addEventListener('click',function(event){
            sixty.style.display='flex';
            if(hundred){hundred.style.backgroundColor='#FFFFFF'}
            if(hundred){hundredn.style.display='none'}
        })
    }
    const iS=document.getElementsByClassName('hidden')[0] as HTMLElement;

  
    const setstatus=document.getElementById('setstatus') as HTMLElement;
    if(setstatus){setstatus.addEventListener('click',function showL(){
    
                iS.style.display='none'});
        }

}, []);





//window.addEventListener("load", function() { 
//    const el = Array.from(document.getElementsByClassName('MuiCardContent-root') as HTMLCollectionOf<HTMLElement>);
//
//const hiddenDiv = Array.from(document.getElementsByClassName('mx-auto hidden') as HTMLCollectionOf<HTMLElement>);
//const hiddenDiv1 = Array.from(document.getElementsByClassName('hidden') as HTMLCollectionOf<HTMLElement>);
//    if(el[0]){el[0].addEventListener('mouseover', function handleMouseOver() {
//        hiddenDiv[0].style.display = 'block';
//        hiddenDiv1[1].style.display = 'flex';
//       
//       });}
//    if(hiddenDiv[0]){hiddenDiv[0].addEventListener('mouseout', function handleMouseOut() {
//       
//         hiddenDiv[0].style.display = 'none';
//         hiddenDiv1[1].style.display = 'none';
//       });}
//       
//       if(hiddenDiv[0]){hiddenDiv[0].addEventListener('mouseover', function handleMouseOver() {
//           hiddenDiv[0].style.display = 'block';
//           hiddenDiv1[1].style.display = 'flex';
//          
//          });}
//          
//          if(hiddenDiv1[1]){hiddenDiv1[1].addEventListener('mouseout', function handleMouseOut() {
//       
//           hiddenDiv[0].style.display = 'none';
//           hiddenDiv1[1].style.display = 'none';
//         });}
//         
//         if(hiddenDiv1[1]){hiddenDiv1[1].addEventListener('mouseover', function handleMouseOver() {
//             hiddenDiv[0].style.display = 'block';
//             hiddenDiv1[1].style.display = 'flex';
//             
//            
//            });}
//            
//       
//       if(el[0]){el[0].addEventListener('mouseout', function handleMouseOut() {
//          
//            hiddenDiv[0].style.display = 'none';
//            hiddenDiv1[1].style.display = 'none';
//          });}
//  
// 
//  });

  //const l=document.querySelector('#location') as HTMLElement;



// ✅ Show hidden DIV on hover
const [status,setStatus]=useState('Enter a Custom status');

    return (
        
        <div >

            <div className="d-flex card-body" style={{width:'600px',textAlign:'center',justifyContent:'center',alignItems:'center',margin:'auto'}}>
            <Link to="/earned" className='hidden'><button className={styles.indigo + " mr-auto"+"hidden"} >Earned</button></Link>
                            <div className='hidden' style={{ height: 4,width:'500px',marginTop:'12px', backgroundColor: '#3F51B5'}}></div>
                            <Link to="/profile" className='hidden' ><img alt="profile" className={(switchProfiler === "1") ? classes.rounded : classes.profile} src={ProfileImage} /></Link>
                            <div className='hidden' style={{ height: 4,width:'500px',marginTop:'12px', backgroundColor: '#3F51B5' }}></div>
                            <Link to="/spent" className='hidden'><button className={styles.indigo+"hidden"} >Spent</button></Link></div>

        <Container className="mt-3">
            <div className="mx-auto" id="clickdiv">
                {/* <Tab /> */}
                <Card className = {"mx-auto mt-3 mb-3 "+styles.profileCard}>
                    <CardContent>
                        <div className="d-flex">
                            <div className="d-flex align-items-center">
                            
                                <img alt="profile" className={(switchProfiler === "1") ? classes.rounded : classes.profile} src={ProfileImage} />
                                <span className="top-0 left-7 absolute  w-10 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
                                
                                
                                {(switchProfiler === "1") ? null : <p className={customStyles.cardAnotherTitle}>{(Object.keys(auth).length > 0) ? auth.user.username : ""}</p>}

                            </div>
                            <button style={{marginTop:'40px',marginRight:'10px'} }id="location" className="top-0 left-7 absolute  w-10 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"><svg xmlns="http://www.w3.org/2000/svg" height="20" fill='#3F51B5' viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg></button>
                            <div className="ml-auto align-self-center">
                            
                                <IconButton
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={handleSettingMenu}
                                >
                                
                                <MoreHoriz />

                                </IconButton>

                                                        
                                {(check.checkedA && switchProfiler === "1") &&
                                    <IconButton
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={() => { setSwitchProfile("2") }}
                                    >
                                        <DateRangeIcon className={customStyles.feedbackColor} />
                                    </IconButton>}
                                {(check.checkedA && switchProfiler === "2") &&
                                    <IconButton
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={() => { setSwitchProfile("1") }}
                                    >
                                        <PermIdentity className={customStyles.feedbackColor} />
                                    </IconButton>}
                                <MuiThemeProvider theme={theme}>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleSettingClose}
                                    >
                                        <MenuItem>
                                            <FormControlLabel
                                                className={classes.templateSwitch}
                                                control={<Checkbox checked={check.checkedA || false} onChange={handleSettingsChange} name="checkedA" />}
                                                label={<Typography>Calendar</Typography>}
                                            />
                                        </MenuItem>
                                        <MenuItem>
                                            <FormControlLabel
                                                className={classes.templateSwitch}
                                                control={<Checkbox checked={check.checkedB || false} onChange={handleSettingsChange} name="checkedB" />}
                                                label={<Typography>Charts</Typography>}
                                            />
                                        </MenuItem>
                                        <MenuItem>
                                            <FormControlLabel
                                                className={classes.templateSwitch}
                                                control={<Checkbox checked={check.checkedC || false} onChange={handleSettingsChange} name="checkedC" />}
                                                label={<Typography>Feedback</Typography>}
                                            />
                                        </MenuItem>
                                        <MenuItem>
                                            <FormControlLabel
                                                className={classes.templateSwitch}
                                                control={<Checkbox checked={check.checkedD || false} onChange={handleSettingsChange} name="checkedD" />}
                                                label={<Typography>Goal</Typography>}
                                            />
                                        </MenuItem>
                                    </Menu>
                                </MuiThemeProvider>
                                {(check.checkedB && switchProfiler === "1") &&
                                    <IconButton
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={() => { setSwitchProfile("3") }}
                                    >
                                        <Equalizer className={customStyles.feedbackColor} />
                                    </IconButton>
                                }
                                {(check.checkedB && switchProfiler === "3") &&
                                    <IconButton
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={() => { setSwitchProfile("1") }}
                                    >
                                        <PermIdentity className={customStyles.feedbackColor} />
                                    </IconButton>
                                }

                                {(check.checkedC && switchProfiler === "1") ?
                                    <IconButton
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={() => { setSwitchProfile("4") }}
                                    >
                                        <FeedbackIcon className={customStyles.feedbackColor} />
                                    </IconButton> : null}

                                {(check.checkedC && switchProfiler === "4") ?
                                    <IconButton
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={() => { setSwitchProfile("1") }}
                                    >
                                        <PermIdentity className={customStyles.feedbackColor} />
                                    </IconButton> : null}

                                {(check.checkedD && switchProfiler === "1") ?
                                    <IconButton
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={() => { setSwitchProfile("5") }}
                                    >
                                        <FlagOutlined className={customStyles.feedbackColor} />
                                    </IconButton> : null}

                                {(check.checkedD && switchProfiler === "5") ?
                                    <IconButton
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={() => { setSwitchProfile("1") }}
                                    >
                                        <PermIdentity className={customStyles.feedbackColor} />
                                    </IconButton> : null}


                                {(check.checkedD && switchProfiler === "5") ?
                                    <span style={{padding:14}}>
                                    <AntSwitch checked={isGraph} onChange={handleGraphChange} name="isGraph" /> </span>: null}
                                {/* {(switchProfiler === "4" && FeedbackList === true) ?
                                <IconButton
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={() => {dispatch(switchFeedback(false))}}
                                >
                                    <ListAlt className={classes.feedbackColor} />
                                </IconButton> : null} */}

                                {/* {(switchProfiler === "4" && FeedbackList) ? <span title="feedback" onClick={() => {dispatch(switchFeedback(false))}}><AddCircleOutlineIcon className={classes.feedbackColor} /></span> : null} */}
                            </div>
                            {/* {(switchProfile === "1") ? <button className={styles.profileButton + " ml-auto"} onClick={() => setSwitchProfile("2")}>My Creations</button> : <button className={styles.profileButton + " ml-auto"} onClick={() => setSwitchProfile("1")}>Profile</button>} */}
                        </div>
                        <div>
                        <div id="addl" className="md:w-2/3">
                            <input style={{display:'none'}}className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="input-status" type="text"/>
                            

                            <button id="setstatus" className=" hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" style={{display:'none'}}>add location</button>
                            </div>
                           
                            
                            
                        </div>
                        {(switchProfiler === "1") ? <div className={classes.spacing}>
                            <p className={customStyles.cardTitle}>{(Object.keys(auth).length > 0) ? auth.user.username : ""}</p>
                            <p id="locationupdate" className={customStyles.cardDesc}>Bengaluru, Karnataka | <img style={{ width: 22 }} src={IndianFlag} alt="india" /> India</p>
                        </div> : null}

                        {(switchProfiler === "1") ? <div>
                            <p contentEditable className={customStyles.lastActive}>I am a Front End Developer. Can build simple, creative and fast-websites. My code is modular and easily understandable.</p>
                        </div> : null}

                        {(switchProfiler === "1") ?
                            <Row className={customStyles.spacing}>
                             

                                         


                                {/* <img src={PlusImage} alt="company5" title="More" className={customStyles.gridImage} /> */}
                                {/* </button> */}
                            </Row> : null}

                        {(switchProfiler === "2") ?
                            <div>
                                {(slotPage) ? <BookSlot date={calendarValue} bookSlotHandler={bookASlot} /> : <div>
                                    {(isCalendar) ?
                                        <List className={classes.root}>
                                            <ListItem
                                                button
                                                selected={selectedIndex === 0}
                                                onClick={(event) => handleListItemClick(event, 0)}
                                            >
                                                <ListItemText className={customStyles.listItem} primary={<Typography style={{width:'60%'}}>Hackathon</Typography>} secondary="Jan 9, 2014 8:30 AM" />
                                                <ListItemSecondaryAction style={{ right: 35 }}>
                                                    5 Minutes
                                        </ListItemSecondaryAction>
                                            </ListItem>
                                            <ListItem
                                                button
                                                selected={selectedIndex === 1}
                                                onClick={(event) => handleListItemClick(event, 1)}>
                                                <ListItemText className={customStyles.listItem} primary={<Typography style={{width:'60%'}}>Design Mockup Review</Typography>} secondary="Jan 7, 2014 9:30 PM" />
                                                <ListItemSecondaryAction style={{ right: 35 }}>
                                                    10 Minutes
                                        </ListItemSecondaryAction>
                                            </ListItem>
                                            <ListItem
                                                button
                                                selected={selectedIndex === 2}
                                                onClick={(event) => handleListItemClick(event, 2)}>
                                                <ListItemText className={customStyles.listItem} primary={<Typography style={{width:'60%'}}>Data Intensive application Bootcamp</Typography>} secondary="July 20, 2014 6:45 PM" />
                                                <ListItemSecondaryAction style={{ right: 35 }}>
                                                    20 Minutes
                                        </ListItemSecondaryAction>
                                            </ListItem>
                                            <div className="d-flex justify-content-center">
                                                <button className={customStyles.buttonDefault} onClick={() => { setCalendarFlag(false) }}>Book An Event</button>
                                            </div>
                                        </List> : <div className="mt-3">
                                            <button title="Back to Events" onClick={() => { setCalendarFlag(true) }} className={customStyles.backButton}><img alt="back" src={BackImage} className={customStyles.backIcon} /></button>
                                            <Calendar
                                                className={customStyles.calendar}
                                                onChange={onCalendarChange}
                                                value={calendarValue}
                                                minDate={new Date()}
                                            />
                                            <div className="d-flex justify-content-center">
                                                <button className={customStyles.buttonDefault} onClick={() => { setSlotPage(true) }}>Select</button>
                                            </div>
                                        </div>}
                                </div>}
                            </div> : null}

                        {(switchProfiler === "3") ?
                            <div>
                                <ProductBar />
                                <Visual />
                            </div> : null
                        }

                        {(switchProfiler === "4") ?
                            <div>
                                <Feedback feedbackHandler={() => { setSwitchProfile("1") }} />
                            </div> : null
                        }

                        {(switchProfiler === "5") ?
                            <div>
                                <GoalFlag showGraph={isGraph} />
                            </div> : null
                        }
                    </CardContent>
                   

<div className='mx-auto ' >

<div className="d-flex">

    <div style={{ width: '60%' }}>
        <p className={styles.customDisable + " pl-1"}>0$</p>
        
        
        <div style={{ height: 4, backgroundColor: 'rgb(84, 165, 56)' }}></div>
    </div>
    <div >
        <p id="sixty" style={{display:'none'}}className={styles.willPayColor + " pr-1"}>60$</p>
        <div style={{ height: 4, backgroundColor: 'rgb(84, 165, 56)' }}></div>
    </div>

    <div style={{ width: '40%' }} className="text-right">
        <p id='hundredn' className={styles.willPayColor + " pr-1"}>100$</p>
        <div id="hundred" style={{ height: 4, backgroundColor: 'rgb(84, 165, 56)' }}></div>
    </div>
</div>
</div>
<div>

</div>
                </Card>
                
            </div>



        </Container>
        <Container className="mt-3">
            <div className="mx-auto">
                {/* <Tab /> */}
                <Card className={classes.root+" "+customStyles.profileCard}>
                    <CardContent>
                        <div className="d-flex">
                            <div className="d-flex align-items-center">
                            <div className="flex justify-between items-center px-2">
                            
                            {(switchProfiler === "1") ?
                            <Row className={customStyles.spacing}>
                             

                                         



                                <p className={customStyles.myCreationsTitle}>MY CREATIONS</p>
                                <Col sm={12} className="d-flex">
                                    <div className={customStyles.grid} onClick={profileSwitcher} style={{ backgroundImage: "url(" + CompanyImage1 + ")" }}></div>
                                    <div className={customStyles.grid} style={{ backgroundImage: "url(" + CompanyImage2 + ")" }}></div>
                                    <div className={customStyles.grid} style={{ backgroundImage: "url(" + CompanyImage3 + ")" }}></div>
                                </Col>
                                <Col sm={12} className="d-flex mt-5">
                                    <div className={customStyles.grid} style={{ backgroundImage: "url(" + CompanyImage4 + ")" }}></div>
                                    <div className={customStyles.grid} style={{ backgroundImage: "url(" + CompanyImage5 + ")" }}></div>
                                    <Link to="/create" className={customStyles.createGrid} style={{ backgroundImage: "url(" + PlusImage + ")" }}></Link>
                                </Col>
                                {/* <img src={PlusImage} alt="company5" title="More" className={customStyles.gridImage} /> */}
                                {/* </button> */}
                            </Row> : null}
                            </div></div></div></CardContent></Card></div></Container>
        </div>
    );
    // ✅ Show hidden DIV on hover

}


export default FeedLayout5;


